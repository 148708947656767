import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ConfirmDeleteDialog = ({ isOpen, onClose, item, handleDelete,name, title }) => {
    if (!isOpen) return null;

    return (
        <>
            {/* Confirmation Dialog */}
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{title || "Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this <strong>{name || ""}</strong>?  action cannot be This undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} style={{ color: 'white', background: "#007dc4" }}>
                        No
                    </Button>
                    <Button onClick={handleDelete} style={{ color: 'white', background: "red" }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmDeleteDialog;
