// Header.js
import React, { useState } from 'react';
import './Header.css'; // Add this for CSS styling
import Cookies from 'js-cookie';
import Logo from '../../src/dashboard/profilePic.jpg';
import { FaBars } from 'react-icons/fa';

const Header = ({ isSidebarOpen, toggleSidebar }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;

    const handleLogout = () => {
        Cookies.remove('user');
        Cookies.remove('token');
        window.location.reload();
    };

    return (
        <div className="header">
        <FaBars className="sidebar-toggle" onClick={toggleSidebar} style={{fontSize:'30px',cursor:'pointer'}}/>
            <div className="header__content">
                
                {/* Profile Icon */}
                <div className="header__profile" onClick={() => setIsPopupOpen(!isPopupOpen)}>
                    <img
                        src={Logo}
                        alt="Profile"
                        className="header__profile-image"
                    />
                </div>
            </div>

            {isPopupOpen && (
                <div className="header__popup">
                    <div className="header__popup-content">
                        <p><strong>Name:</strong> {user?.name || 'User Name'}</p>
                        <p><strong>Email:</strong> {user?.email || 'user@example.com'}</p>
                        <button className="header__logout-button" onClick={handleLogout}>
                            Logout
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;
