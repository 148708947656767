import React, { useEffect, useState } from 'react';
import './../Mediclaim.css';
import Popup from './Vehicle-Popup'
import DashboardLayout from '../../components/DashboardLayout';
import { deleteMediclaimUser, getAllConsumers, getAllMedicalimConsumer, getAllVehicleConsumerData, getAllVerticle, getAllVerticleUser } from '../../serviceAPI/userAPI';
import viewImage from './../view.png';
import Cookies from 'js-cookie';
import ConfirmDeleteDialog from '../../components/confirm.diloag';
import VehicleTable from './Vehicle-Table';
import RenewalVehiclePopupDetail from './vehicleRenewalDetail';

const Vehicle = () => {

    const [data, setData] = useState([]);
    const [verticle, setVerticle] = useState([]);
    const [verticleUser, setVerticleUser] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [view, setView] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [heading, setHeading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [detail, setDetail] = useState(null);
    const [viewIndex, setViewIndex] = useState(null);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);

    const [deleteItem, setDeleteItem] = useState(null); // Stores item to delete
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    const categoryId = Cookies.get('category');
    const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
        setView(false)
        setEditIndex(null);
    };

    useEffect(() => {
        getAllMediclaimConsumerData()
    }, []);

    const getAllMediclaimConsumerData = async () => {
        const consumerData = await getAllVehicleConsumerData();
        if (consumerData?.data && consumerData?.data?.length) {
            const processedData = consumerData?.data.map((item, index) => ({
                ...item,
            }));
            setData(processedData);
            let isRenew = localStorage.getItem('isRenew') || '';
            let VehicleID = localStorage.getItem('VehicleID') || '';
            if (isRenew && VehicleID) {
                const globalIndex = processedData.findIndex((item) => item.vehicle_user_id == VehicleID);
                if (globalIndex !== -1) {
                    setEditIndex(globalIndex);
                    // localStorage.removeItem('isRenew')
                    // localStorage.removeItem('MediclaimID')
                    setIsPopupOpen(true);
                }
            }
        } else {
            setData([]);
        }
        setHeading([{ key: 'user_pk_vehicle_id.username', head: 'Name' }, { key: 'user_pk_vehicle_id.email', head: 'Email' }, { key: 'user_pk_vehicle_id.mobileNumber', head: 'Mobile Number' }]);
    };


    const handleViewClick = (userData) => {

        console.log(userData)
        console.log(data,'data')
        const globalIndex = data.findIndex((item) => item.vehicle_user_id === userData.vehicle_user_id);
        console.log(globalIndex, 'globalIndex')
        if (globalIndex !== -1) {
            setViewIndex(globalIndex);
            setDetail(data[globalIndex])
            setIsViewPopupOpen(true)
        }
        // // Add your view click handling logic here
        // console.log('View clicked for item:', item);
        // setEditIndex(index);
        // setView(true)
        // setIsPopupOpen(true);
        // // navigate(`/mediclaim/company/${item.mediclaim_company_id}`)
    };

    const handleEdit = (userData) => {
        const globalIndex = data.findIndex((item) => item.vehicle_user_id === userData.vehicle_user_id);
        if (globalIndex !== -1) {
            setEditIndex(globalIndex);
            setIsPopupOpen(true);
        }
    };

    // Opens delete confirmation popup
    const handleOpenDeletePopup = (item) => {
        console.log('Delete popup should open for:', item);
        setDeleteItem(item);
        setIsDeletePopupOpen(true);
    };

    // Confirms deletion and calls the API
    const handleDeleteConfirm = async () => {
        if (deleteItem) {
            try {
                if (deleteItem.user_id) {
                    let obj = { user_id: deleteItem.user_id }
                    await deleteMediclaimUser(obj); // API call to delete
                    fetchApi(); // Refresh table
                }

            } catch (error) {
                console.error("Error deleting mediclaim:", error);
            }
            setIsDeletePopupOpen(false);
            setDeleteItem(null);
        }
    };
    console.log(isDeletePopupOpen, 'isDeletePopupOpen')

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(direction);
    };

    const fetchApi = () => {
        getAllMediclaimConsumerData();
    };

    const toggleViewPopup = () => {
        setIsViewPopupOpen(!isViewPopupOpen);
        setViewIndex(null);
        setDetail(null)
    };

    const handleVerticalChange = (index, newVertical) => {
        const updatedData = data.map((item, idx) =>
            idx === index ? { ...item, vertical: newVertical } : item
        );
        setData(updatedData);
    };

    console.log(localStorage.getItem('isRenew'))
    console.log(localStorage.getItem('VehicleID'))
    return (
        <DashboardLayout>
            <div className='d-flex justify-content-between align-items-center dashboard-title'>
                <h1>Vehicle</h1>
                {((user && user.role_id !== 1) || (categoryId && categoryId.includes(4))) && <button className="btn btn-white" onClick={togglePopup}>Add</button>}
                {isPopupOpen && <Popup
                    isOpen={isPopupOpen}
                    onClose={togglePopup}
                    fetchApi={fetchApi}
                    initialData={editIndex !== null ? data[editIndex] : null}
                    view={view}
                />}
            </div>
            {isDeletePopupOpen && (
                <ConfirmDeleteDialog
                    isOpen={isDeletePopupOpen}
                    onClose={() => setIsDeletePopupOpen(false)}
                    item={deleteItem}
                    name={deleteItem?.['user.username']}
                    handleDelete={handleDeleteConfirm}
                    title="Delete Mediclaim Confirmation"
                />
            )}
            {isViewPopupOpen && (
                <RenewalVehiclePopupDetail
                    isOpen={isViewPopupOpen}
                    onClose={toggleViewPopup}
                    initialData={detail}
                />
            )}
            {(
                <VehicleTable
                    headings={heading}
                    data={data}
                    verticleOptions={verticle}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    handleEdit={handleEdit}
                    handleViewClick={handleViewClick}
                    handleDelete={handleOpenDeletePopup}
                    handleSort={handleSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setItemsPerPage={setItemsPerPage}
                    onVerticalChange={handleVerticalChange}
                />
            )}
        </DashboardLayout>
    );
};

export default Vehicle;
