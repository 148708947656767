import React, { useEffect, useState } from 'react';
import './Loan.css';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { deleteLoanConsumerUser, getAllConsumers, getAllLoanConsumer, getAllLoanConsumerDetail, getAllLoanInterestedConsumer, getAllVerticle, getAllVerticleUser, updateLoanStatus } from '../serviceAPI/userAPI';
import { useToaster } from '../components/Toaster';
import LoanConsumerPopup from '../components/Loan-consumer-popup';
import Cookies from 'js-cookie';
import LoanInterestedTable from '../components/Loan-Table/Loan-Interested'
import PopupModal from '../components/View-Loan-Details.popup';
import ConfirmDeleteDialog from '../components/confirm.diloag';


const Loani = () => {
  const addToast = useToaster()
  const categoryId = Cookies.get('category');
  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

  const getStartOfFinancialYear = () => {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
    return new Date(year, 3, 2).toISOString().split('T')[0]; // 1st April of the financial year
  };

  const getEndOfFinancialYear = () => {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() + 1 : today.getFullYear();
    console.log(year, 'ss')
    return new Date(year, 3, 1).toISOString().split('T')[0]; // Ensures 31st March
  };
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(getStartOfFinancialYear());
  const [endDate, setEndDate] = useState(getEndOfFinancialYear());
  const [detail, setDetail] = useState(null);
  const [verticle, setVerticle] = useState([]);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [viewIndex, setViewIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const [deleteItem, setDeleteItem] = useState(null); // Stores item to delete
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);


  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const fetchApi = () => {
    getAllLoanConsumerData();
  };


  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanInterestedConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'details.login_details.loanDate', head: 'Loan Date' }, { key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Loan Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' },{ key: 'details.loan_createAt', head: 'Created Date' }, { key: 'status', head: 'Status' }]);
  };


  const getLoanConsumerDetail = async (laon_id) => {
    const consumerData = await getAllLoanConsumerDetail({ laon_id }); // Send laon_id to get loan details
    if (consumerData?.data) {
      setDetail(consumerData?.data); // Set the detailed data to state
    }
  };


  const handleEdit = (userData) => {
    const globalIndex = data.findIndex((item) => item.user_consumer_id === userData.user_consumer_id);
    if (globalIndex !== -1) {
      setEditIndex(globalIndex);
      setIsEditPopupOpen(true);
    }

  };

  const handleView = async (userData) => {
    const globalIndex = data.findIndex((item) => item.user_consumer_id === userData.user_consumer_id);
    if (globalIndex !== -1) {
      setViewIndex(globalIndex);
      const laon_id = data[globalIndex]?.details?.laon_id; // Get loan ID from selected row
      await getLoanConsumerDetail(laon_id); // Fetch detailed loan data for view
      setIsViewPopupOpen(true)
    }

  };

  const toggleEditPopup = () => {
    setIsEditPopupOpen(!isEditPopupOpen);
    setEditIndex(null);
  };

  const toggleViewPopup = () => {
    setIsViewPopupOpen(!isViewPopupOpen);
    setViewIndex(null);
  };


  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    const consumerData = await getAllLoanInterestedConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'details.login_details.loanDate', head: 'Loan Date' }, { key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Loan Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' },{ key: 'details.loan_createAt', head: 'Created Date' },{ key: 'status', head: 'Status' }]);
  };

  // Confirms deletion and calls the API
  const handleDeleteConfirm = async () => {
    if (deleteItem) {
      try {
        if (deleteItem.user_consumer_id) {
          let obj = { user_id: deleteItem.user_consumer_id, deleteType: 'notAssign' }

          if(deleteItem?.details?.laon_id){
            obj.loan_id = deleteItem?.details?.laon_id;
            obj.deleteType = 'Assign'
          }
          await deleteLoanConsumerUser(obj); // API call to delete
          fetchApi(); // Refresh table
        }

      } catch (error) {
        console.error("Error deleting mediclaim:", error);
      }
      setIsDeletePopupOpen(false);
      setDeleteItem(null);
    }
  };

  // Opens delete confirmation popup
  const handleOpenDeletePopup = (item) => {
    console.log('Delete popup should open for:', item);
    setDeleteItem(item);
    setIsDeletePopupOpen(true);
  }

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Loan</h1>
      </div>
      <div className="filter-section">
        <div className="filter-inputs">
          <div>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
      {isEditPopupOpen && (
        <LoanConsumerPopup
          isOpen={isEditPopupOpen}
          onClose={toggleEditPopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />
      )}

      {isViewPopupOpen && (
        <PopupModal
          isOpen={isViewPopupOpen}
          onClose={toggleViewPopup}
          fetchApi={fetchApi}
          initialData={detail}
          mode="view" // Pass the mode prop as 'view' for viewing the loan details
        />
      )}
      {isDeletePopupOpen && (
        <ConfirmDeleteDialog
          isOpen={isDeletePopupOpen}
          onClose={() => setIsDeletePopupOpen(false)}
          item={deleteItem}
          name={deleteItem?.['userConsumers.username']}
          handleDelete={handleDeleteConfirm}
          title="Delete Mediclaim Confirmation"
        />
      )}
      {(
        <LoanInterestedTable
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleDelete={handleOpenDeletePopup}
          handleView={handleView}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </DashboardLayout>
  );
};

export default Loani;
