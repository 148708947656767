import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './User.css';
import Popup from '../components/user-popup';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getAllInquieries, getRoleUserList } from '../serviceAPI/userAPI';
import InquiryPopup from '../components/Inquiry -popup';

const Inquiry = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [editIndex, setEditIndex] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [userData, setUserData] = useState([]);
  const [heading, setHeading] = useState([]);

  useEffect(() => {
    getRoleUserData();
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  const handleEdit = (userDataA) => {
    const globalIndex = data.findIndex((item) => item.user_id === userDataA.user_id);
    if (globalIndex !== -1) {
      setEditIndex(globalIndex);
      setIsPopupOpen(true);
    }
  };

  const fetchApi = () => {
    getRoleUserData();
  };

  const getRoleUserData = async () => {
    const roleData = await getAllInquieries();
    if (roleData?.data && roleData?.data?.length) {
      setData(roleData?.data);
    }
    setHeading([{ key: 'user_name', head: 'User Name' }, { key: 'email', head: 'Email' }, { key: 'mobile_no', head: 'Mobile Number' }, { key: 'services', head: 'Services' }]);
  };


  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Inquiry Consumer</h1>
        {isPopupOpen && <InquiryPopup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />}
      </div>
      {
        <>
          <Table
            headings={heading}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
            handleEdit={handleEdit}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            handleSort={handleSort}
            setItemsPerPage={setItemsPerPage}
          />
        </>
      }
    </DashboardLayout>
  );
};

export default Inquiry;
