import React, { useEffect, useState } from 'react';
import Popup from '../components/Loandisburse-popup';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanConsumerDetail, getAllLoanDisburseConsumer, getAllMedicalimConsumerData, getAllMedicalimConsumerRenewalData, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';
import LoanDisburseTable from '../components/Loan-Table/Loan-Completed';
import PopupModal from '../components/View-Loan-Details.popup';
import MediclaimRenewalTable from '../components/MediclaimRenewalTable';
import viewImage from './view.png';
import Cookies from 'js-cookie';
import RenewalPopupDetail from '../components/View-Renewal-Sheet-Medicalim';
import Papa from 'papaparse';

const RenewalSheet = () => {

    const getStartOfMonth = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 2).toISOString().split('T')[0]; // 1st day of the month
    };

    const getEndOfMonth = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth() + 1, 1).toISOString().split('T')[0]; // Last day of the month
    };
    const [data, setData] = useState([]);
    const [verticle, setVerticle] = useState([]);
    const [verticleUser, setVerticleUser] = useState([]);
    const [startDate, setStartDate] = useState(getStartOfMonth());
    const [endDate, setEndDate] = useState(getEndOfMonth());
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [detail, setDetail] = useState(null);
    const [viewIndex, setViewIndex] = useState(null);
    const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [heading, setHeading] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const [view, setView] = useState(false);


    const categoryId = Cookies.get('category');
    const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

    const toggleViewPopup = () => {
        setIsViewPopupOpen(!isViewPopupOpen);
        setViewIndex(null);
        setDetail(null)
    };

    useEffect(() => {
        getAllMediclaimConsumerData()
    }, []);

    const getAllMediclaimConsumerData = async () => {
        const consumerData = await getAllMedicalimConsumerRenewalData({ startDate, endDate });
        if (consumerData?.data && consumerData?.data?.length) {
            const processedData = consumerData?.data.map((item, index) => ({
                ...item,
                view: <a><img className="building-view" src={viewImage} alt="View" style={{ cursor: 'pointer' }} onClick={() => handleView(item, index)} /></a>
            }));
            setData(processedData);
        } else {
            setData([]);
        }
        setHeading([{ key: 'user.username', head: 'Name' }, { key: 'user.email', head: 'Email' }, { key: 'user.mobileNumber', head: 'Mobile Number' },{ key: 'runningPolicy.PolicyNumber', head: 'Policy Number' },{ key: 'runningPolicy.ExpiryDate', head: 'Expiry Date' },{ key: 'runningPolicy.IssueDate', head: 'Issue Date' },{ key: 'renew', head: 'Renew' }]);
    };

    const handleSearch = async () => {
        if (!startDate || !endDate) {
            alert("Please select both start and end dates.");
            return;
        }
        const consumerData = await getAllMedicalimConsumerRenewalData({ startDate, endDate });
        if (consumerData?.data && consumerData?.data?.length) {
            setData(consumerData?.data);
        } else {
            setData([]);
        }
        setHeading([{ key: 'user.username', head: 'Name' }, { key: 'user.email', head: 'Email' }, { key: 'user.mobileNumber', head: 'Mobile Number' },{ key: 'runningPolicy.PolicyNumber', head: 'Policy Number' },{ key: 'runningPolicy.ExpiryDate', head: 'Expiry Date' },{ key: 'runningPolicy.IssueDate', head: 'Issue Date' },{ key: 'renew', head: 'Renew' }]);
    };

    const handleView = async (userData) => {
        console.log(userData)
        const globalIndex = data.findIndex((item) => item.id === userData.id);
        if (globalIndex !== -1) {
            setViewIndex(globalIndex);
            setDetail(data[globalIndex])
            setIsViewPopupOpen(true)
        }
    };

    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortDirection(direction);
    };

    const handleVerticalChange = (index, newVertical) => {
        const updatedData = data.map((item, idx) =>
            idx === index ? { ...item, vertical: newVertical } : item
        );
        setData(updatedData);
    };

      const exportToCSV = () => {
            if (data.length === 0) {
                alert("No data available to export.");
                return;
            }
            console.log(data,'datat')
        
            const csvData = data.map((item,index) => ({
                "S.No": Number(index+1),
                Name: item?.['user.username'] || '',
                Email: item?.['user.email'] || '',
                "Mobile Number": item?.['user.mobileNumber'] || '',
                "Policy Number": item.runningPolicy?.PolicyNumber || '',
                "Expiry Date": item.runningPolicy?.ExpiryDate || '',
                "Issue Date": item.runningPolicy?.PolicyIssuedDate || ''
            }));
            console.log(csvData,'csvData')
        
            const csv = Papa.unparse(csvData);
        
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", `Mediclaim_Renewal_Sheet_${new Date().toISOString().split('T')[0]}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

    return (
        <DashboardLayout>
            <div className='d-flex justify-content-between align-items-center dashboard-title'>
                <h1>Renewal Sheet</h1>
                {isViewPopupOpen && (
                    <RenewalPopupDetail
                        isOpen={isViewPopupOpen}
                        onClose={toggleViewPopup}
                        initialData={detail}
                    />
                )}
            </div>
            <div className="filter-section">
                <div className="filter-inputs">
                    <div>
                        <label>Start Date:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="form-control"
                        />
                    </div>
                    <div>
                        <label>End Date:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </div>
                <div>
                    <button className="search-button" onClick={handleSearch}>Search</button>
                    <button className="export-button" onClick={exportToCSV} style={{marginLeft:'10px'}}>Export to CSV</button>
                </div>
            </div>
            {(
                <MediclaimRenewalTable
                    headings={heading}
                    data={data}
                    verticleOptions={verticle}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    handleView={handleView}
                    handleSort={handleSort}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    setItemsPerPage={setItemsPerPage}
                    onVerticalChange={handleVerticalChange}
                />
            )}
        </DashboardLayout>
    );
};

export default RenewalSheet;
