import React, { useEffect, useState } from 'react';
import '../../components/popup-u.css';
import { addMediclaimUser, addNewCode, addNewCompanyType, addNewDocument, addNewPolicyPlan, addNewPolicyType, addNewReferenceType, addNewVehicle, addVehicleUser, DOWNLOAD_URL, getAllCodes, getAllCompanyTypes, getAllDocuments, getAllMediclaimCompany, getAllMediclaimProduct, getAllPlicyPlans, getAllPlicyTypes, getAllReferenceTypes, getAllVehicles, updateMediclaimUser, updateVehicleser } from '../../serviceAPI/userAPI';
import { useToaster } from '../../components/Toaster';

const VehiclePopup = ({ isOpen, onClose, fetchApi, initialData, view }) => {
    const addToast = useToaster()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [companyData, setCompanyData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [productType, setProductType] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [showAddCodeField, setShowAddCodeField] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [newCodeName, setNewCodeName] = useState('');
    const [showAddPolicyField, setShowAddPolicyField] = useState(false);
    const [policyPlans, setPolicyPlans] = useState([]);
    const [newPolicyName, setNewPolicyName] = useState('');

    const [showAddPolicyTypeField, setShowAddPolicyTypeField] = useState(false);
    const [policyTypes, setPolicyTypes] = useState([]);
    const [newPolicyTypeName, setNewPolicyTypeName] = useState('');


    const [showAddCompanyTypeField, setShowAddCompanyTypeField] = useState(false);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [newCompanyTypeName, setNewCompanyTypeName] = useState('');

    const [showAddReferenceTypeField, setShowAddReferenceTypeField] = useState(false);
    const [referenceTypes, setReferenceTypes] = useState([]);
    const [newReferenceTypeName, setNewReferenceTypeName] = useState('');

    const [showAddDocField, setShowAddDocField] = useState(false);
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState("");
    const [documents, setDocuments] = useState({});

    const [formState, setFormState] = useState({
        formData: {
            Name: '',
            MobileNumber: '',
            ContactPersonName: '',
            ContactPersonNo: '',
            VehicleNumber: '',
            Email: '',
            policyRadio: '',
            nomineeRadio: '',
            Make: '',
            Model: '',
            ManufacturingYear: '',
            EngineNumber: '',
            ChassisNumber: '',
            VehicleId: '',
            ReferenceId: '',
            AgentName: '',
            AgentCode: '',
            AgentContactNumber: '',

            // Running Policy Data
            runningPolicy: {
                PolicyNumber: '',
                PolicyTenure: '',
                PremiumAmount: '',
                NomineeName: '',
                NomineeRelation: '',
                NomineeAge: '',
                NomineeDob: '',
                PolicyTo: '',
                PolicyFrom: '',
                PolicyPlanTypeId: '',
                PolicyTypeId: '',
                CompanyId: '',
                ExpiryDate: '',
                CurrentPolicyFile: '',
                PolicyIssuedDate: '',
                NCB: '',
                IDV: '',
                Vendor: '',
                isNomineeFlag: '',
            },

            // Previous Policy Data (For Renewals and Portability)
            previousPolicy: {
                PolicyNumber: '',
                PolicyTenure: '',
                PremiumAmount: '',
                NomineeName: '',
                NomineeRelation: '',
                NomineeAge: '',
                NomineeDob: '',
                PolicyTo: '',
                PolicyFrom: '',
                PolicyPlanTypeId: '',
                PolicyTypeId: '',
                CompanyId: '',
                ExpiryDate: '',
                CurrentPolicyFile: '',
                PolicyIssuedDate: '',
                NCB: '',
                IDV: '',
                claim: '',
                isNomineeFlag: '',
                Vendor: '',
            },
            documents: []
        },
        errors: {},
    });

    console.log(documents, 'documents')

    const fetchVehicles = async () => {
        const consumerData = await getAllVehicles();
        if (consumerData?.data && consumerData?.data?.length) {
            setVehicles(consumerData?.data);
        } else {
            setVehicles([]);
        }
    };

    const fetchPolicy = async () => {
        const consumerData = await getAllPlicyPlans();
        if (consumerData?.data && consumerData?.data?.length) {
            setPolicyPlans(consumerData?.data);
        } else {
            setPolicyPlans([]);
        }
    };

    const fetchPolicyType = async () => {
        const consumerData = await getAllPlicyTypes();
        if (consumerData?.data && consumerData?.data?.length) {
            setPolicyTypes(consumerData?.data);
        } else {
            setPolicyTypes([]);
        }
    };

    const fetchCompanyType = async () => {
        const consumerData = await getAllCompanyTypes();
        if (consumerData?.data && consumerData?.data?.length) {
            setCompanyTypes(consumerData?.data);
        } else {
            setCompanyTypes([]);
        }
    };

    const fetchReferenceType = async () => {
        const consumerData = await getAllReferenceTypes();
        if (consumerData?.data && consumerData?.data?.length) {
            setReferenceTypes(consumerData?.data);
        } else {
            setReferenceTypes([]);
        }
    };

    const fetchDocuments = async () => {
        const consumerData = await getAllDocuments();
        if (consumerData?.data && consumerData?.data?.length) {
            setCategories(consumerData?.data);
        } else {
            setCategories([]);
        }
    };

    useEffect(() => {
        if (initialData && initialData.vehicle_user_id) {
            const {
                vehicle_policy_type,
                nominee_type,
                company_name,
                contact_person_name,
                contact_person_no,
                vehicle_number,
                vehicle_id,
                reference_id,
                make,
                model,
                manufacturing_year,
                engine_number,
                agentName,
                agentCode,
                agentContactNumber,
                chassis_number,
                previousPolicy,
                runningPolicy,
            } = initialData;

            console.log(runningPolicy, 'runningPolicys')
            setFormState({
                formData: {
                    Name: initialData['user_pk_vehicle_id.username'] || '',
                    MobileNumber: initialData['user_pk_vehicle_id.mobileNumber'] || '',
                    ContactPersonName: contact_person_name || '',
                    ContactPersonNo: contact_person_no || '',
                    VehicleNumber: vehicle_number || '1',
                    Email: initialData['user_pk_vehicle_id.email'] || '',
                    policyRadio: vehicle_policy_type || '',
                    nomineeRadio: nominee_type || '',
                    Make: make || '',
                    Model: model || '',
                    ManufacturingYear: manufacturing_year || '',
                    EngineNumber: engine_number || '',
                    ChassisNumber: chassis_number || '',
                    VehicleId: vehicle_id || '',
                    ReferenceId: reference_id || '',
                    AgentName: agentName || '',
                    AgentCode: agentCode || '',
                    AgentContactNumber: agentContactNumber || '',

                    runningPolicy: {
                        PolicyNumber: runningPolicy?.PolicyNumber || '',
                        PolicyTenure: runningPolicy?.PolicyTenure || '',
                        PremiumAmount: runningPolicy?.PremiumAmount || '',
                        NomineeName: runningPolicy?.NomineeName || '',
                        NomineeRelation: runningPolicy?.NomineeRelation || '',
                        NomineeAge: runningPolicy?.NomineeAge || '',
                        NomineeDob: runningPolicy?.NomineeDob ? runningPolicy?.NomineeDob.slice(0, 10) : '',
                        PolicyTo: runningPolicy?.PolicyTo ? runningPolicy?.PolicyTo.slice(0, 10) : '',
                        PolicyFrom: runningPolicy?.PolicyFrom ? runningPolicy?.PolicyFrom.slice(0, 10) : '',
                        PolicyPlanTypeId: runningPolicy?.policy_plan_id || '',
                        PolicyTypeId: runningPolicy?.policy_type_id || '',
                        CompanyId: runningPolicy?.company_id || '',
                        ExpiryDate: runningPolicy?.ExpiryDate ? runningPolicy?.ExpiryDate.slice(0, 10) : '',
                        CurrentPolicyFile: runningPolicy?.CurrentPolicyFile || '',
                        PolicyIssuedDate: runningPolicy?.PolicyIssuedDate ? runningPolicy?.PolicyIssuedDate.slice(0, 10) : '',
                        NCB: runningPolicy?.NCB || '',
                        IDV: runningPolicy?.IDV || '',
                        Vendor: runningPolicy?.Vendor || '',
                        isNomineeFlag: runningPolicy?.isNomineeFlag || ''
                    },

                    previousPolicy: {
                        PolicyNumber: previousPolicy?.PolicyNumber || '',
                        PolicyTenure: previousPolicy?.PolicyTenure || '',
                        PremiumAmount: previousPolicy?.PremiumAmount || '',
                        NomineeName: previousPolicy?.NomineeName || '',
                        NomineeRelation: previousPolicy?.NomineeRelation || '',
                        NomineeAge: previousPolicy?.NomineeAge || '',
                        NomineeDob: previousPolicy?.NomineeDob ? previousPolicy?.NomineeDob.slice(0, 10) : '',
                        PolicyTo: previousPolicy?.PolicyTo ? previousPolicy?.PolicyTo.slice(0, 10) : '',
                        PolicyFrom: previousPolicy?.PolicyFrom ? previousPolicy?.PolicyFrom.slice(0, 10) : '',
                        PolicyPlanTypeId: previousPolicy?.policy_plan_id || '',
                        PolicyTypeId: previousPolicy?.policy_type_id || '',
                        CompanyId: previousPolicy?.company_id || '',
                        ExpiryDate: previousPolicy?.ExpiryDate ? previousPolicy?.ExpiryDate.slice(0, 10) : '',
                        CurrentPolicyFile: previousPolicy?.CurrentPolicyFile || '',
                        PolicyIssuedDate: previousPolicy?.PolicyIssuedDate ? previousPolicy?.PolicyIssuedDate.slice(0, 10) : '',
                        NCB: previousPolicy?.NCB || '',
                        IDV: previousPolicy?.IDV || '',
                        claim: previousPolicy?.claim || '',
                        Vendor: previousPolicy?.Vendor || '',
                        isNomineeFlag: runningPolicy?.isNomineeFlag || '',
                    },
                    documents: initialData.documents
                },

            });

            let isRenew = localStorage.getItem('isRenew') || '';
            let VehicleID = localStorage.getItem('VehicleID') || '';
            console.log(isRenew, 'isRenew')
            console.log(VehicleID, 'isRenew')
            if (isRenew && VehicleID) {
                setFormState(prevState => {

                    let updatedPreviousPolicy = {
                        PolicyNumber: runningPolicy?.PolicyNumber || '',
                        PolicyTenure: runningPolicy?.PolicyTenure || '',
                        PremiumAmount: runningPolicy?.PremiumAmount || '',
                        NomineeName: runningPolicy?.NomineeName || '',
                        NomineeRelation: runningPolicy?.NomineeRelation || '',
                        NomineeAge: runningPolicy?.NomineeAge || '',
                        NomineeDob: runningPolicy?.NomineeDob ? runningPolicy?.NomineeDob.slice(0, 10) : '',
                        PolicyTo: runningPolicy?.PolicyTo ? runningPolicy?.PolicyTo.slice(0, 10) : '',
                        PolicyFrom: runningPolicy?.PolicyFrom ? runningPolicy?.PolicyFrom.slice(0, 10) : '',
                        PolicyPlanTypeId: runningPolicy?.policy_plan_id || '',
                        PolicyTypeId: runningPolicy?.policy_type_id || '',
                        CompanyId: runningPolicy?.company_id || '',
                        ExpiryDate: runningPolicy?.ExpiryDate ? runningPolicy?.ExpiryDate.slice(0, 10) : '',
                        CurrentPolicyFile: runningPolicy?.CurrentPolicyFile || '',
                        PolicyIssuedDate: runningPolicy?.PolicyIssuedDate ? runningPolicy?.PolicyIssuedDate.slice(0, 10) : '',
                        NCB: runningPolicy?.NCB || '',
                        IDV: runningPolicy?.IDV || '',
                        Vendor: runningPolicy?.Vendor || '',
                    };

                    return {
                        ...prevState,
                        formData: {
                            ...prevState.formData,
                            policyRadio: vehicle_policy_type == 'Fresh' ? "Renewal" : vehicle_policy_type == 'Renewal' ? "Renewal" : "Portability",
                            previousPolicy: updatedPreviousPolicy
                        }
                    };
                });

                // **Ensure localStorage is cleared only after state update**
                setTimeout(() => {
                    localStorage.removeItem('isRenew');
                    localStorage.removeItem('VehicleID');
                }, 1000);
            }
        }
        fetchVehicles();
        fetchPolicy();
        fetchPolicyType();
        fetchCompanyType();
        fetchReferenceType();
        fetchDocuments();
    }, [initialData]);

    console.log(formState.formData, initialData?.documents)

    const handleSaveNewVehicle = async () => {
        if (!newCodeName.trim()) {
            alert("Vehicle name cannot be empty");
            return;
        }

        const response = await addNewVehicle({ vehicle_name: newCodeName });
        if (response.status) {
            console.log(response.data.code_name, 'sdjsj')
            fetchVehicles();
            setNewCodeName('');
            setShowAddCodeField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleSaveNewPolicy = async () => {
        if (!newPolicyName.trim()) {
            alert("Policy name cannot be empty");
            return;
        }

        const response = await addNewPolicyPlan({ policy_name: newPolicyName });
        if (response.status) {
            console.log(response.data.code_name, 'sdjsj')
            fetchPolicy();
            setNewPolicyName('');
            setShowAddPolicyField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleSaveNewPolicyType = async () => {
        if (!newPolicyTypeName.trim()) {
            alert("Policy name cannot be empty");
            return;
        }

        const response = await addNewPolicyType({ policy_type_name: newPolicyTypeName });
        if (response.status) {
            // console.log(response.data.code_name, 'sdjsj')
            fetchPolicyType();
            setNewPolicyTypeName('');
            setShowAddPolicyTypeField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleSaveNewCompanyType = async () => {
        if (!newCompanyTypeName.trim()) {
            alert("Policy name cannot be empty");
            return;
        }

        const response = await addNewCompanyType({ company_name: newCompanyTypeName });
        if (response.status) {
            // console.log(response.data.code_name, 'sdjsj')
            fetchCompanyType();
            setNewCompanyTypeName('');
            setShowAddCompanyTypeField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleSaveNewReferenceType = async () => {
        if (!newReferenceTypeName.trim()) {
            alert("Reference name cannot be empty");
            return;
        }

        const response = await addNewReferenceType({ reference_name: newReferenceTypeName });
        if (response.status) {
            // console.log(response.data.code_name, 'sdjsj')
            fetchReferenceType();
            setNewReferenceTypeName('');
            setShowAddReferenceTypeField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleSaveNewDoc = async () => {
        if (!newCategory.trim()) {
            alert("Category name cannot be empty");
            return;
        }

        const response = await addNewDocument({ doc_name: newCategory });
        if (response.status) {
            console.log(response.data.doc_name, 'sdjsj')
            fetchDocuments();
            setNewCategory('');
            setShowAddDocField(false); // Hide the input field and save button
        } else {
            alert("Failed to add code");
        }
    };

    const handleFileDocChange = (categoryId, e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setFormState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    documents: [
                        ...(prevState.formData.documents || []).filter(doc => doc.categoryId !== categoryId),
                        { categoryId, file }
                    ],
                },
            }));
        } else {
            alert("Please upload a valid PDF file.");
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setFormState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    runningPolicy: {
                        ...prevState.formData?.runningPolicy,
                        CurrentPolicyFile: file,
                    },
                },
            }));
        } else {
            alert("Please upload a valid PDF file.");
        }
    };

    const handleFilePreviousChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setFormState(prevState => ({
                ...prevState,
                formData: {
                    ...prevState.formData,
                    previousPolicy: {
                        ...prevState.formData?.previousPolicy,
                        CurrentPolicyFile: file,
                    },
                },
            }));
        } else {
            alert("Please upload a valid PDF file.");
        }
    };

    useEffect(() => {
        getCompanyData()
    }, []);

    const validateForm = () => {
        const errors = { runningPolicy: {}, previousPolicy: {} };
        const { formData } = formState;

        // Helper function for common validations
        const isValidNumber = (value) => !isNaN(value) && value >= 0;
        const isValidDate = (date) => date && !isNaN(new Date(date).getTime());
        console.log(currentStep, 'sddssd')

        // Step 1: Basic Information Validation
        if (currentStep === 1) {
            if (!formData.Name) errors.Name = 'Name is required';
            if (!formData.Email || !/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Valid Email is required';
            if (!formData.MobileNumber || formData.MobileNumber.length < 10) errors.MobileNumber = 'Valid Mobile Number is required';
            if (!formData.policyRadio) errors.policyRadio = 'Please select Policy Type';
            if (!formData.nomineeRadio) errors.nomineeRadio = 'Please select Type';
            if (!formData.ContactPersonName) errors.ContactPersonName = 'Contact Person Name is required';
            if (!formData.ContactPersonNo || formData.ContactPersonNo.length < 10) errors.ContactPersonNo = 'Valid Mobile Number is required';
        }

        // Step 2: Policyholder & Family Validation
        else if (currentStep === 2) {

        }

        else if (currentStep === 3) {
            if (!formData.VehicleNumber) errors.VehicleNumber = 'Vehicle Number is required';
            if (!formData.Make) errors.Make = 'Make is required';
            if (!formData.Model) errors.Model = 'Model is required';
            if (!formData.ManufacturingYear) errors.ManufacturingYear = 'Manufacturing Year is required';
            if (!formData.EngineNumber) errors.EngineNumber = 'Engine Number is required';
            if (!formData.ChassisNumber) errors.ChassisNumber = 'Chassis Number is required';
            if (!formData.VehicleId) errors.VehicleId = 'Please select vehicle is required';
        }

        // Step 3: Policy Dates & Insurance Period
        else if (currentStep === 4) {
            if (!isValidDate(formData.runningPolicy.PolicyFrom)) errors.runningPolicy.PolicyFrom = 'Period of Insurance "From" date is required';
            if (!isValidDate(formData.runningPolicy.PolicyTo)) errors.runningPolicy.PolicyTo = 'Period of Insurance "To" date is required';

            if (formData?.nomineeRadio === "Corporate") {
                if (!formData.runningPolicy?.isNomineeFlag) errors.runningPolicy.isNomineeFlag = 'Nominee Flag is required';
            }

            if (
                isValidDate(formData.runningPolicy.PolicyFrom) &&
                isValidDate(formData.runningPolicy.PolicyTo) &&
                new Date(formData.runningPolicy.PolicyTo) <= new Date(formData.runningPolicy.PolicyFrom)
            ) {
                errors.runningPolicy.PolicyTo = 'The "To" date must be later than the "From" date';
            }

            if (!isValidDate(formData.runningPolicy.PolicyIssuedDate)) errors.runningPolicy.PolicyIssuedDate = 'Policy Issued Date is required';

            if (
                !isValidDate(formData.runningPolicy.PolicyIssuedDate)
            ) {
                errors.runningPolicy.PolicyIssuedDate = 'Policy Issued Date is not valid';
            }

            if (!formData.runningPolicy.PolicyNumber) errors.runningPolicy.PolicyNumber = 'Policy Number is required';
            if (!formData.runningPolicy.PolicyTenure) errors.runningPolicy.PolicyTenure = 'Policy Tenure is required';
            if (!formData.runningPolicy.PremiumAmount) errors.runningPolicy.PremiumAmount = 'Valid Premium Amount is required';

            if (formData?.runningPolicy?.isNomineeFlag === "Yes" || formData?.nomineeRadio === "Individule") {
                if (!formData.runningPolicy.NomineeName) errors.runningPolicy.NomineeName = 'Nominee Name is required';
                if (!formData.runningPolicy.NomineeRelation) errors.runningPolicy.NomineeRelation = 'Nominee Relation is required';
                if (!isValidDate(formData.runningPolicy.NomineeDob)) errors.runningPolicy.NomineeDob = 'Valid Nominee Date of Birth is required';
            }

            if (!isValidDate(formData.runningPolicy.ExpiryDate)) errors.runningPolicy.ExpiryDate = 'Valid Expiry Date is required';
            if (!formData.runningPolicy.CurrentPolicyFile) errors.runningPolicy.CurrentPolicyFile = 'Current Policy File is required';
            if (!isValidDate(formData.runningPolicy.PolicyIssuedDate)) errors.runningPolicy.PolicyIssuedDate = 'Policy Issued Date is required';
            if (!formData.runningPolicy.PolicyPlanTypeId) errors.runningPolicy.PolicyPlanTypeId = 'Policy Plan Type is required';
            if (!formData.runningPolicy.PolicyTypeId) errors.runningPolicy.PolicyTypeId = 'Policy Type is required';
            if (!formData.runningPolicy.CompanyId) errors.runningPolicy.CompanyId = 'Company Id is required';
            if (!formData.runningPolicy.Vendor) errors.runningPolicy.Vendor = 'Vendor information is required';
            if (!formData.runningPolicy.NCB) errors.runningPolicy.NCB = 'Valid NCB is required';
            if (!formData.runningPolicy.IDV) errors.runningPolicy.IDV = 'Valid IDV is required';

        }

        // Step 5: Agent Information Validation
        else if (currentStep === 5) {
            if (!formData.AgentName) errors.AgentName = 'Agent Name is required';
            if (!formData.AgentContactNumber || formData.AgentContactNumber.length < 10) errors.AgentContactNumber = 'Valid Agent Contact Number is required';
            if (!formData.AgentCode) errors.AgentCode = 'Agent Code is required';
            if (!formData.ReferenceId) errors.ReferenceId = 'Reference is required';
        }

        // Step 4: Policy Details Validation
        else if (currentStep === 6) {
            if (formData.policyRadio !== 'Fresh') {

                if (formData?.nomineeRadio === "Corporate") {
                    if (!formData.previousPolicy.isNomineeFlag) errors.previousPolicy.isNomineeFlag = 'Nominee Flag is required';
                }

                if (formData?.previousPolicy?.isNomineeFlag === "Yes" || formData?.nomineeRadio === "Individule") {
                    if (!formData.previousPolicy.NomineeName) errors.previousPolicy.NomineeName = 'Previous Nominee Name is required';
                    if (!formData.previousPolicy.NomineeRelation) errors.previousPolicy.NomineeRelation = 'Previous Nominee Relation is required';
                    if (!isValidDate(formData.previousPolicy.NomineeDob)) errors.previousPolicy.NomineeDob = 'Valid Nominee Date of Birth is required';
                }



                if (!formData.previousPolicy.PolicyNumber) errors.previousPolicy.PolicyNumber = 'Previous Policy Number is required';
                if (!isValidDate(formData.previousPolicy.PolicyFrom)) errors.previousPolicy.PolicyFrom = 'Previous Policy "From" date is required';
                if (!isValidDate(formData.previousPolicy.PolicyTo)) errors.previousPolicy.PolicyTo = 'Previous Policy "To" date is required';
                if (
                    isValidDate(formData.previousPolicy.PolicyFrom) &&
                    isValidDate(formData.previousPolicy.PolicyTo) &&
                    new Date(formData.previousPolicy.PolicyTo) <= new Date(formData.previousPolicy.PolicyFrom)
                ) {
                    errors.runningPolicy.PolicyTo = 'The "To" date must be later than the "From" date';
                }
                if (!formData.previousPolicy.PolicyTenure) errors.previousPolicy.PolicyTenure = 'Policy Tenure is required';
                if (!formData.previousPolicy.PolicyPlanTypeId) errors.previousPolicy.PolicyPlanTypeId = 'Previous Policy Plan Type is required';
                if (!isValidDate(formData.previousPolicy.PolicyIssuedDate)) errors.previousPolicy.PolicyIssuedDate = 'Policy Issued Date is required';
                if (!formData.previousPolicy.PolicyTypeId) errors.previousPolicy.PolicyTypeId = 'Previous Policy Type is required';
                if (!formData.previousPolicy.CompanyId) errors.previousPolicy.CompanyId = 'Previous Company Id is required';
                if (!formData.previousPolicy.PremiumAmount) errors.previousPolicy.PremiumAmount = 'Valid Previous Premium Amount is required';
                if (!formData.previousPolicy.CurrentPolicyFile) errors.previousPolicy.CurrentPolicyFile = 'Previous Policy File is required';
                if (!formData.previousPolicy.claim) errors.previousPolicy.claim = 'Claim is required';
                if (!formData.previousPolicy.Vendor) errors.previousPolicy.Vendor = 'Vendor information is required';
                if (!formData.previousPolicy.NCB) errors.previousPolicy.NCB = 'Valid NCB is required';
                if (!formData.previousPolicy.IDV) errors.previousPolicy.IDV = 'Valid IDV is required';
            }
        }

        // Update errors in the state
        setFormState((prevState) => ({
            ...prevState,
            errors,
        }));
        console.log(errors, 'eee')

        const filteredErrors = Object.keys(errors).filter(
            key => key !== "runningPolicy" && key !== "previousPolicy"
        );

        console.log(formState.formData)

        const isValid = filteredErrors.length === 0 &&
            Object.keys(errors.runningPolicy).length === 0 &&
            Object.keys(errors.previousPolicy).length === 0;
        // Return true if no errors
        return isValid;
    };

    const redirectpageproduct = (pdfPath) => {
        // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
        const file_path = DOWNLOAD_URL + pdfPath;
        console.log(file_path, "file");

        // Create a temporary <a> element
        const a = document.createElement("a");
        a.href = file_path;
        a.target = "_blank"; // Open in a new tab
        a.download = file_path; // Extract filename from path

        // Trigger the click programmatically
        document.body.appendChild(a);
        a.click();

        // Remove the element after triggering
        document.body.removeChild(a);
    };
    const getCompanyData = async () => {
        const roleData = await getAllMediclaimCompany();
        if (roleData?.data && roleData?.data?.length)
            setCompanyData(roleData?.data)
    }

    const handleNext = () => {
        if (view) {
            if (currentStep < 6) {
                // setCurrentStep(currentStep + 1);
                if (currentStep === 4 && (formState?.formData?.policyRadio === 'Fresh')) {
                    setCurrentStep(5);
                } else {
                    setCurrentStep(currentStep + 1);
                }
            }
        } else {
            if (validateForm()) {
                if (currentStep < 6) {
                    // setCurrentStep(currentStep + 1);
                    if (currentStep === 4 && (formState?.formData?.policyRadio === 'Fresh')) {
                        setCurrentStep(5);
                    } else {
                        setCurrentStep(currentStep + 1);
                    }
                }
            }
        }

    };

    const handlePrev = () => {
        if (currentStep > 1) {
            // setCurrentStep(currentStep - 1);
            if (currentStep === 4 && (formState?.formData?.policyRadio === 'Fresh')) {
                setCurrentStep(3);
            } else {
                setCurrentStep(currentStep - 1);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // Prevent form from submitting if already submitting

        // Only submit when we're on Step 5
        if ((currentStep === 5 && formState.formData.policyRadio === "Fresh") || (currentStep === 6)) {
            if (validateForm()) {
                // setIsSubmitting(true); // Set flag to true to prevent multiple submissions

                console.log('Form Submitted', formState.formData);

                // Validate mobile number format
                const regex = /^\d{10}$/;
                if (!regex.test(formState?.formData?.MobileNumber)) {
                    alert('Mobile number is invalid');
                    // setIsSubmitting(false); // Reset the flag
                    return;
                }

                const formDataPDF = new FormData();
                if (formState?.formData?.runningPolicy?.CurrentPolicyFile) {
                    formDataPDF.append('CurrentPolicyFile', formState?.formData?.runningPolicy?.CurrentPolicyFile);
                }
                if (formState?.formData?.previousPolicy?.CurrentPolicyFile) {
                    formDataPDF.append('PreviousCurrentPolicyFile', formState.formData.previousPolicy.CurrentPolicyFile);
                }
                // Append dynamically uploaded documents
                if (formState?.formData?.documents?.length > 0) {
                    formState.formData.documents.forEach((doc, index) => {
                        console.log(doc.file, "documents" + index)
                        formDataPDF.append("documents" + index, doc.file);
                        formDataPDF.append("categoryId" + index, doc.categoryId);
                    });
                }

                // Prepare payload with form data and family members
                const payload = {
                    ...formState.formData
                };

                console.log('Payload:', payload);

                try {
                    let response;
                    if (initialData && initialData.vehicle_user_id) {
                        // Update existing record
                        payload.user_id = initialData.user_id;
                        payload.vehicle_user_id = initialData.vehicle_user_id;
                        formDataPDF.append("data", JSON.stringify(payload));
                        response = await updateVehicleser(formDataPDF, payload.vehicle_user_id, addToast);
                    } else {
                        formDataPDF.append("data", JSON.stringify(payload));
                        // Add new record
                        response = await addVehicleUser(formDataPDF, addToast);
                    }

                    if (response.status) {
                        // Success logic
                        fetchApi();
                        onClose();
                    }
                } catch (error) {
                    console.error('Error during submission:', error);
                } finally {
                    setIsSubmitting(false); // Reset the flag after submission attempt
                }
            }
        }
    };


    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const handleNomineeDateChange = (e) => {
        const dateOfBirth = e.target.value;
        const age = calculateAge(dateOfBirth);
        setFormState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                runningPolicy: {
                    ...prevState.formData.runningPolicy,
                    NomineeDob: dateOfBirth,
                    NomineeAge: age
                }
            }
        }));
    };

    const handleNomineeDatePreviousChange = (e) => {
        const dateOfBirth = e.target.value;
        const age = calculateAge(dateOfBirth);
        setFormState(prevState => ({
            ...prevState,
            formData: {
                ...prevState.formData,
                previousPolicy: {
                    ...prevState.formData.previousPolicy,
                    NomineeDob: dateOfBirth,
                    NomineeAge: age
                }
            }
        }));
    };
    const handlePolicyRatio = (e) => {
        const newPolicyType = e.target.value;
        setFormState(prevState => {
            let updatedPreviousPolicy = { ...prevState.formData.previousPolicy };
            console.log(initialData)

            if (initialData?.medicliam_policy_type === 'Fresh' && (newPolicyType === 'Renewal' || newPolicyType === "Portability")) {
                updatedPreviousPolicy = {
                    PolicyNumber: prevState.formData.runningPolicy.PolicyNumber || '',
                    Zone: prevState.formData.runningPolicy.Zone || '',
                    PolicyTenure: prevState.formData.runningPolicy.PolicyTenure || '',
                    PremiumAmount: prevState.formData.runningPolicy.PremiumAmount || '',
                    NomineeName: prevState.formData.runningPolicy.NomineeName || '',
                    NomineeRelation: prevState.formData.runningPolicy.NomineeRelation || '',
                    NomineeAge: prevState.formData.runningPolicy.NomineeAge || '',
                    NomineeDob: prevState.formData.runningPolicy.NomineeDob || '',
                    PolicyFrom: prevState.formData.runningPolicy.PolicyFrom || '',
                    PolicyTo: prevState.formData.runningPolicy.PolicyTo || '',
                    RenewDate: '',
                    PdfFile: '',
                    ClaimExpireInPolicy: '',
                    PreviousPolicyNumber: '',
                    ClaimStatementPDFfile: '',
                };
                setProductType('')
            }

            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    policyRadio: newPolicyType,
                    previousPolicy: updatedPreviousPolicy
                }
            };
        });
    }

    const handleNomineeTypeRatio = (e) => {
        const newPolicyType = e.target.value;
        setFormState(prevState => {
            let updatedPreviousPolicy = { ...prevState.formData.previousPolicy };
            console.log(initialData)

            if (initialData?.medicliam_policy_type === 'Individule' && (newPolicyType === 'Renewal' || newPolicyType === "Portability")) {
                updatedPreviousPolicy = {
                    PolicyNumber: prevState.formData.runningPolicy.PolicyNumber || '',
                    Zone: prevState.formData.runningPolicy.Zone || '',
                    PolicyTenure: prevState.formData.runningPolicy.PolicyTenure || '',
                    PremiumAmount: prevState.formData.runningPolicy.PremiumAmount || '',
                    NomineeName: prevState.formData.runningPolicy.NomineeName || '',
                    NomineeRelation: prevState.formData.runningPolicy.NomineeRelation || '',
                    NomineeAge: prevState.formData.runningPolicy.NomineeAge || '',
                    NomineeDob: prevState.formData.runningPolicy.NomineeDob || '',
                    PolicyFrom: prevState.formData.runningPolicy.PolicyFrom || '',
                    PolicyTo: prevState.formData.runningPolicy.PolicyTo || '',
                    RenewDate: '',
                    PdfFile: '',
                    ClaimExpireInPolicy: '',
                    PreviousPolicyNumber: '',
                    ClaimStatementPDFfile: '',
                };
                setProductType('')
            }

            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    policyRadio: newPolicyType,
                    previousPolicy: updatedPreviousPolicy
                }
            };
        });
    }

    const handleAddCodeClick = () => {
        setShowAddCodeField(true); // Show the input field and save button
    };

    const handleAddReferenceClick = () => {
        setShowAddReferenceTypeField(true); // Show the input field and save button
    };

    const handleAddPolicyPlanClick = () => {
        setShowAddPolicyField(true); // Show the input field and save button
    };

    const handleAddPolicyTypeClick = () => {
        setShowAddPolicyTypeField(true); // Show the input field and save button
    };

    const handleAddCompanyTypeClick = () => {
        setShowAddCompanyTypeField(true); // Show the input field and save button
    };

    const handleAddCategoryClick = () => {
        setShowAddDocField(true); // Show the input field and save button
    };

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='popup-header d-flex justify-content-between align-items-center'>
                    <h2>{initialData ? 'Edit Vehicle' : 'Add Vehicle'}</h2>
                    <span className="close-btn" onClick={onClose}>&times;</span>
                </div>
                <form className="popup-form" onSubmit={handleSubmit}>
                    {currentStep === 1 && (
                        <>
                            <div className='mediclaim-h1'>
                                <h3>Consumer Details</h3>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="Name"
                                        required
                                        className='form-control'
                                        placeholder="Name"
                                        value={formState.formData.Name}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                Name: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.Name && <p className="text-danger">{formState.errors.Name}</p>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name="Email"
                                        required
                                        className='form-control'
                                        placeholder="Email"
                                        value={formState.formData.Email}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                Email: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.Email && <p className="text-danger">{formState.errors.Email}</p>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Mobile Number</label>
                                    <input
                                        type="text"
                                        name="MobileNumber"
                                        required
                                        className='form-control'
                                        placeholder="Mobile Number"
                                        value={formState.formData.MobileNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                MobileNumber: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.MobileNumber && <p className="text-danger">{formState.errors.MobileNumber}</p>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Contact Person Name</label>
                                    <input
                                        type="text"
                                        name="ContactPersonName"
                                        required
                                        className='form-control'
                                        placeholder="Contact Person Name"
                                        value={formState.formData.ContactPersonName}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                ContactPersonName: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.ContactPersonName && <p className="text-danger">{formState.errors.ContactPersonName}</p>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Contact Person Mobile Number</label>
                                    <input
                                        type="text"
                                        name="ContactPersonNo"
                                        required
                                        className='form-control'
                                        placeholder="Contact Person Mobile Number"
                                        value={formState.formData.ContactPersonNo}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                ContactPersonNo: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.ContactPersonNo && <p className="text-danger">{formState.errors.ContactPersonNo}</p>}
                                </div>
                                <div className='col-md-6 mb-4 d-grid'>
                                    <label>Policy Type</label>
                                    <label className='flex align-items-center'>
                                        <input
                                            type="radio"
                                            name="policyRadio"
                                            required
                                            className='me-2'
                                            value="Fresh"
                                            checked={formState.formData.policyRadio === 'Fresh'}
                                            disabled={initialData?.vehicle_policy_type === "Renewal" || initialData?.vehicle_policy_type === "Portability"}
                                            onChange={(e) => handlePolicyRatio(e)}
                                        />
                                        Fresh
                                    </label>
                                    <label className='flex align-items-center'>
                                        <input
                                            type="radio"
                                            name="policyRadio"
                                            className='me-2'
                                            value="Renewal"
                                            checked={formState.formData.policyRadio === 'Renewal'}
                                            onChange={(e) => handlePolicyRatio(e)}
                                        />
                                        Renewal
                                    </label>
                                    <label className='flex align-items-center'>
                                        <input
                                            type="radio"
                                            name="policyRadio"
                                            className='me-2'
                                            value="Portability"
                                            checked={formState.formData.policyRadio === 'Portability'}
                                            onChange={(e) => handlePolicyRatio(e)}
                                        />
                                        Portability
                                    </label>
                                    {formState?.errors?.policyRadio && <p className="text-danger">{formState.errors.policyRadio}</p>}
                                </div>
                                <div className='col-md-6 mb-4 d-grid'>
                                    <label>Type</label>
                                    <label className='flex align-items-center'>
                                        <input
                                            type="radio"
                                            name="nomineeRadio"
                                            required
                                            className='me-2'
                                            value="Individule"
                                            checked={formState.formData.nomineeRadio === 'Individule'}
                                            onChange={(e) => setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    nomineeRadio: e.target.value
                                                }
                                            }))}
                                        />
                                        Individule
                                    </label>
                                    <label className='flex align-items-center'>
                                        <input
                                            type="radio"
                                            name="nomineeRadio"
                                            className='me-2'
                                            value="Corporate"
                                            checked={formState.formData.nomineeRadio === 'Corporate'}
                                            onChange={(e) => setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    nomineeRadio: e.target.value
                                                }
                                            }))}
                                        />
                                        Corporate
                                    </label>
                                    {formState?.errors?.nomineeRadio && <p className="text-danger">{formState.errors.nomineeRadio}</p>}
                                </div>
                            </div>
                        </>
                    )}
                    {currentStep === 2 && (
                        <>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <label className="form-label">Documents</label>
                                    {categories.map((category) => {
                                        // Find the document for the current category
                                        const currentDocument = formState?.formData?.documents?.find(doc => doc.categoryId === category.categoryId);
                                        const currentFile = currentDocument?.file;
                                        console.log(currentFile, 'currentFile')
                                        const fileName = currentFile instanceof File ? currentFile?.name : currentFile;
                                        const isFlag = currentFile instanceof File ? false : true;
                                        console.log(fileName, 'fileName')

                                        return (
                                            <div key={category.categoryId} className="mb-3">
                                                <label>Upload {category.doc_name} PDF</label>

                                                {/* Display existing document */}
                                                {isFlag && fileName ? (
                                                    <div>
                                                        <a
                                                            href={fileName}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={(e) => {
                                                                e.preventDefault(); // Prevent default link behavior
                                                                redirectpageproduct(fileName); // Use the redirect function
                                                            }}
                                                            style={{
                                                                color: "blue",
                                                                textDecoration: "underline",
                                                                fontSize: "12px",
                                                            }}
                                                        >
                                                            View Existing PDF {fileName && fileName?.slice(0, 5) + '...'}
                                                        </a>
                                                    </div>
                                                ) : !isFlag && fileName && (
                                                    <p>Selected File: {fileName}</p>
                                                )}

                                                {/* File upload input */}
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="application/pdf"
                                                    onChange={(e) => handleFileDocChange(category.categoryId, e)}
                                                />
                                            </div>
                                        );
                                    })}

                                    {!showAddDocField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddCategoryClick}
                                        >
                                            Add Document
                                        </button>
                                    </div>}
                                </div>
                                <div className="col-md-6 mb-4">
                                    {showAddDocField &&
                                        <div>
                                            <label>Category Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter new category name"
                                                value={newCategory}
                                                onChange={(e) => setNewCategory(e.target.value)}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={handleSaveNewDoc}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={() => setShowAddDocField(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                    {currentStep === 3 && (
                        <>
                            <div className="mediclaim-h1">
                                <h3>Vehicle Details</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <label className="form-label">Vehicle</label>
                                    <select
                                        name="VehicleId"
                                        className="form-select"
                                        value={formState?.formData?.VehicleId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                VehicleId: e.target.value
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Vehicle</option>
                                        {vehicles.map((code) => (
                                            <option key={code.vehicle_id} value={code.vehicle_id}>
                                                {code.vehicle_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.VehicleId && <div className="text-danger">{formState.errors?.VehicleId}</div>}
                                    {!showAddCodeField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddCodeClick}
                                        >
                                            Add Vehicle
                                        </button>
                                    </div>}
                                </div>
                                <div className="col-md-6 mb-4">
                                    {showAddCodeField &&
                                        <div>
                                            <label className="form-label">Vehicle Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="New Vehicle Name"
                                                value={newCodeName}
                                                onChange={(e) => setNewCodeName(e.target.value)}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={handleSaveNewVehicle}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={() => setShowAddCodeField(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>

                                        </div>
                                    }
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label>Vehicle Number</label>
                                    <input
                                        type="text"
                                        name="VehicleNumber"
                                        className="form-control"
                                        placeholder="Vehicle Number"
                                        value={formState?.formData?.VehicleNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                VehicleNumber: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.VehicleNumber && <div className="text-danger">{formState.errors?.VehicleNumber}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Make</label>
                                    <input
                                        type="text"
                                        name="Make"
                                        className="form-control"
                                        placeholder="Make"
                                        value={formState?.formData?.Make}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                Make: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.Make && <div className="text-danger">{formState.errors?.Make}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Model</label>
                                    <input
                                        type="text"
                                        name="Model"
                                        className="form-control"
                                        placeholder="Model"
                                        value={formState?.formData?.Model}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                Model: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.Model && <div className="text-danger">{formState.errors?.Model}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Manufacturing Year</label>
                                    <input
                                        type="text"
                                        name="ManufacturingYear"
                                        className="form-control"
                                        placeholder="Manufacturing Year"
                                        value={formState?.formData?.ManufacturingYear}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                ManufacturingYear: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.ManufacturingYear && <div className="text-danger">{formState.errors?.ManufacturingYear}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Engine Number</label>
                                    <input
                                        type="text"
                                        name="EngineNumber"
                                        className="form-control"
                                        placeholder="Engine Number"
                                        value={formState?.formData?.EngineNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                EngineNumber: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.EngineNumber && <div className="text-danger">{formState.errors?.EngineNumber}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Chassis Number</label>
                                    <input
                                        type="text"
                                        name="ChassisNumber"
                                        className="form-control"
                                        placeholder="Chassis Number"
                                        value={formState?.formData?.ChassisNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                ChassisNumber: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState.errors?.ChassisNumber && <div className="text-danger">{formState.errors?.ChassisNumber}</div>}
                                </div>
                            </div>
                        </>
                    )}
                    {currentStep === 4 && (
                        <>
                            <div className="mediclaim-h1">
                                <h3>Running Policy Details</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-4">
                                    <label>Policy Number</label>
                                    <input
                                        type="text"
                                        name="RunningPolicyNumber"
                                        className="form-control"
                                        placeholder="Policy Number"
                                        value={formState?.formData?.runningPolicy?.PolicyNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    PolicyNumber: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.PolicyNumber && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyNumber}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Type</label>
                                    <select
                                        name="PolicyTypeId"
                                        className="form-select"
                                        value={formState?.formData?.runningPolicy?.PolicyTypeId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    PolicyTypeId: e.target.value
                                                }
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Policy Type</option>
                                        {policyTypes.map((code) => (
                                            <option key={code.policy_type_id} value={code.policy_type_id}>
                                                {code.policy_type_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.runningPolicy?.PolicyTypeId && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyTypeId}</div>}
                                    {!showAddPolicyTypeField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddPolicyTypeClick}
                                        >
                                            Add Policy Plan
                                        </button>
                                    </div>}
                                </div>
                                {showAddPolicyTypeField && <div className="col-md-6 mb-4">
                                    <div>
                                        <label>Policy Type</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="New Policy Plan"
                                            value={newPolicyTypeName}
                                            onChange={(e) => setNewPolicyTypeName(e.target.value)}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={handleSaveNewPolicyType}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={() => setShowAddPolicyTypeField(false)}
                                            >
                                                Close
                                            </button>
                                        </div>

                                    </div>

                                </div>}

                                <div className="col-md-6 mb-4">
                                    <label>Company Name</label>
                                    <select
                                        name="CompanyId"
                                        className="form-select"
                                        value={formState?.formData?.runningPolicy?.CompanyId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    CompanyId: e.target.value
                                                }
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Company Name</option>
                                        {companyTypes.map((code) => (
                                            <option key={code.company_id} value={code.company_id}>
                                                {code.company_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.runningPolicy?.CompanyId && <div className="text-danger">{formState.errors?.runningPolicy?.CompanyId}</div>}
                                    {!showAddCompanyTypeField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddCompanyTypeClick}
                                        >
                                            Add Company Name
                                        </button>
                                    </div>}
                                </div>
                                {showAddCompanyTypeField && <div className="col-md-6 mb-4">
                                    <div>
                                        <label>Company Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="New Company Name"
                                            value={newCompanyTypeName}
                                            onChange={(e) => setNewCompanyTypeName(e.target.value)}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={handleSaveNewCompanyType}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={() => setShowAddCompanyTypeField(false)}
                                            >
                                                Close
                                            </button>
                                        </div>

                                    </div>

                                </div>}

                                <div className="col-md-6 mb-4">
                                    <label>Policy Tenure</label>
                                    <input
                                        type="number"
                                        name="RunningPolicyTenure"
                                        className="form-control"
                                        placeholder="Policy Tenure"
                                        value={formState?.formData?.runningPolicy?.PolicyTenure}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    PolicyTenure: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.PolicyTenure && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyTenure}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Premium Amount</label>
                                    <input
                                        type="number"
                                        name="RunningPremiumAmount"
                                        className="form-control"
                                        placeholder="Premium Amount"
                                        value={formState?.formData?.runningPolicy?.PremiumAmount}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    PremiumAmount: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.PremiumAmount && <div className="text-danger">{formState.errors?.runningPolicy?.PremiumAmount}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>From</label>
                                    <input
                                        type="date"
                                        name="poifrom"
                                        className="form-control"
                                        value={formState?.formData?.runningPolicy?.PolicyFrom}
                                        onChange={(e) => {
                                            const poifrom = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    runningPolicy: {
                                                        ...prevState.formData?.runningPolicy,
                                                        PolicyFrom: poifrom
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    {formState.errors?.runningPolicy?.PolicyFrom && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyFrom}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>To</label>
                                    <input
                                        type="date"
                                        name="poito"
                                        className="form-control"
                                        value={formState.formData?.runningPolicy?.PolicyTo}
                                        onChange={(e) => {
                                            const poito = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    runningPolicy: {
                                                        ...prevState.formData?.runningPolicy,
                                                        PolicyTo: poito,
                                                        ExpiryDate: poito // Automatically set Expiry Date based on "To" date
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    {formState.errors?.runningPolicy?.PolicyTo && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyTo}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>NCB</label>
                                    <input
                                        type="text"
                                        name="NCB"
                                        className="form-control"
                                        placeholder="NCB"
                                        value={formState?.formData?.runningPolicy?.NCB}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    NCB: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.NCB && <div className="text-danger">{formState.errors?.runningPolicy?.NCB}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>IDV</label>
                                    <input
                                        type="text"
                                        name="IDV"
                                        className="form-control"
                                        placeholder="IDV"
                                        value={formState?.formData?.runningPolicy?.IDV}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    IDV: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.IDV && <div className="text-danger">{formState.errors?.runningPolicy?.IDV}</div>}
                                </div>


                                <div className="col-md-6 mb-4">
                                    <label>Policy Issued Date</label>
                                    <input
                                        type="date"
                                        name="PolicyIssuedDate"
                                        className="form-control"
                                        value={formState?.formData?.runningPolicy?.PolicyIssuedDate}
                                        onChange={(e) => {
                                            const policyIssuedDate = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    runningPolicy: {
                                                        ...prevState.formData?.runningPolicy,
                                                        PolicyIssuedDate: policyIssuedDate
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    {formState.errors?.runningPolicy?.PolicyIssuedDate && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyIssuedDate}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Expiry Date</label>
                                    <input
                                        type="date"
                                        name="ExpiryDate"
                                        className="form-control"
                                        value={formState?.formData?.runningPolicy?.ExpiryDate}
                                        disabled
                                    />
                                    {formState.errors?.runningPolicy?.ExpiryDate && <div className="text-danger">{formState.errors?.runningPolicy?.ExpiryDate}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Plan Type</label>
                                    <select
                                        name="PolicyPlanTypeId"
                                        className="form-select"
                                        value={formState?.formData?.runningPolicy?.PolicyPlanTypeId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    PolicyPlanTypeId: e.target.value
                                                }
                                            }

                                        }))}
                                    >
                                        <option value="" disabled>Select Policy Plan Type</option>
                                        {policyPlans.map((code) => (
                                            <option key={code.policy_plan_id} value={code.policy_plan_id}>
                                                {code.policy_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.runningPolicy?.PolicyPlanTypeId && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyPlanTypeId}</div>}
                                    {!showAddPolicyField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddPolicyPlanClick}
                                        >
                                            Add Policy Plan
                                        </button>
                                    </div>}

                                </div>
                                {showAddPolicyField && <div className="col-md-6 mb-4">
                                    <div>
                                        <label>Policy Plan Type</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="New Policy Plan"
                                            value={newPolicyName}
                                            onChange={(e) => setNewPolicyName(e.target.value)}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={handleSaveNewPolicy}
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-success ms-2"
                                                onClick={() => setShowAddPolicyField(false)}
                                            >
                                                Close
                                            </button>
                                        </div>

                                    </div>

                                </div>}

                                <div className="col-md-6 mb-4">
                                    <label>Vendor</label>
                                    <input
                                        type="text"
                                        name="Vendor"
                                        className="form-control"
                                        placeholder="Vendor"
                                        value={formState?.formData?.runningPolicy?.Vendor}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                runningPolicy: {
                                                    ...prevState.formData?.runningPolicy,
                                                    Vendor: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.runningPolicy?.Vendor && <div className="text-danger">{formState.errors?.runningPolicy?.Vendor}</div>}
                                </div>

                                {formState?.formData?.nomineeRadio === "Corporate" && <div className="col-md-6 mb-4">
                                    <label className="form-label">Nominee Details</label>
                                    <select
                                        name="isNomineeFlag"
                                        className="form-select"
                                        value={formState?.formData?.runningPolicy?.isNomineeFlag}
                                        onChange={(e) => {
                                            const isNomineeFlag = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    runningPolicy: {
                                                        ...prevState.formData?.runningPolicy,
                                                        isNomineeFlag: isNomineeFlag
                                                    }
                                                }
                                            }));
                                        }}
                                    >
                                        <option value="" disabled>Noimnee Details</option>
                                        {["Yes", "No"].map((code) => (
                                            <option key={code} value={code}>
                                                {code}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.runningPolicy?.isNomineeFlag && <div className="text-danger">{formState.errors?.runningPolicy?.isNomineeFlag}</div>}
                                </div>}


                                {(formState.formData?.runningPolicy?.isNomineeFlag === "Yes" || formState.formData?.nomineeRadio === "Individule") && (
                                    <>
                                        <div className="col-md-6 mb-4">
                                            <label>Nominee Name</label>
                                            <input
                                                type="text"
                                                name="NomineeName"
                                                className="form-control"
                                                placeholder="Nominee Name"
                                                value={formState?.formData?.runningPolicy?.NomineeName || ""}
                                                onChange={(e) => setFormState(prevState => ({
                                                    ...prevState,
                                                    formData: {
                                                        ...prevState.formData,
                                                        runningPolicy: {
                                                            ...prevState.formData?.runningPolicy,
                                                            NomineeName: e.target.value
                                                        }
                                                    }
                                                }))}
                                            />
                                            {formState.errors?.runningPolicy?.NomineeName && (
                                                <div className="text-danger">{formState.errors?.runningPolicy?.NomineeName}</div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <label>Nominee Relation</label>
                                            <input
                                                type="text"
                                                name="NomineeRelation"
                                                className="form-control"
                                                placeholder="Nominee Relation"
                                                value={formState?.formData?.runningPolicy?.NomineeRelation || ""}
                                                onChange={(e) => setFormState(prevState => ({
                                                    ...prevState,
                                                    formData: {
                                                        ...prevState.formData,
                                                        runningPolicy: {
                                                            ...prevState.formData?.runningPolicy,
                                                            NomineeRelation: e.target.value
                                                        }
                                                    }
                                                }))}
                                            />
                                            {formState.errors?.runningPolicy?.NomineeRelation && (
                                                <div className="text-danger">{formState.errors?.runningPolicy?.NomineeRelation}</div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <label>Nominee DOB</label>
                                            <input
                                                type="date"
                                                name="NomineeDob"
                                                className="form-control"
                                                value={formState?.formData?.runningPolicy?.NomineeDob || ""}
                                                onChange={handleNomineeDateChange}
                                            />
                                            {formState.errors?.runningPolicy?.NomineeDob && (
                                                <div className="text-danger">{formState.errors?.runningPolicy?.NomineeDob}</div>
                                            )}
                                        </div>

                                        <div className="col-md-6 mb-4">
                                            <label>Nominee Age</label>
                                            <input
                                                type="text"
                                                name="RunningNomineeAge"
                                                className="form-control"
                                                placeholder="Nominee Age"
                                                value={formState?.formData?.runningPolicy?.NomineeAge || ""}
                                                readOnly
                                            />
                                            {formState.errors?.runningPolicy?.NomineeAge && (
                                                <div className="text-danger">{formState.errors?.runningPolicy?.NomineeAge}</div>
                                            )}
                                        </div>
                                    </>
                                )}




                                <div className="col-md-6 mb-4">
                                    <label>Upload Running Policy PDF</label>
                                    {initialData?.runningPolicy?.CurrentPolicyFile && (
                                        <div>
                                            <a
                                                href={initialData?.runningPolicy?.CurrentPolicyFile}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default link behavior
                                                    redirectpageproduct(initialData?.runningPolicy?.CurrentPolicyFile); // Use the redirectpage function
                                                }}
                                                style={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                View Existing PDF {initialData?.runningPolicy?.CurrentPolicyFile.slice(0, 5) + '...'}
                                            </a>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="CurrentPolicyFile"
                                        className="form-control"
                                        accept="application/pdf"
                                        onChange={handleFileChange}
                                    />
                                    {formState?.formData?.runningPolicy?.CurrentPolicyFile && (
                                        <p>Selected File: {formState.formData.runningPolicy.CurrentPolicyFile.name}</p>
                                    )}
                                    {formState.errors?.runningPolicy?.CurrentPolicyFile && <div className="text-danger">{formState.errors?.runningPolicy?.CurrentPolicyFile}</div>}
                                </div>
                            </div>
                        </>
                    )}

                    {currentStep === 5 && (
                        <>
                            <div className='mediclaim-h1'>
                                <h3>Agent Details</h3>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <label>Agent Name</label>
                                    <input
                                        type="text"
                                        name="AgentName"
                                        className='form-control'
                                        placeholder="Agent Name"
                                        value={formState.formData?.AgentName}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                AgentName: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.AgentName && <div className="text-danger">{formState.errors.AgentName}</div>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Agent Contact Number</label>
                                    <input
                                        type="text"
                                        name="AgentContactNumber"
                                        className='form-control'
                                        placeholder="Agent Contact Number"
                                        value={formState.formData?.AgentContactNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                AgentContactNumber: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.AgentContactNumber && <div className="text-danger">{formState.errors.AgentContactNumber}</div>}
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <label>Agent Code</label>
                                    <input
                                        type="text"
                                        name="AgentCode"
                                        className='form-control'
                                        placeholder="Agent Code"
                                        value={formState.formData?.AgentCode}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                AgentCode: e.target.value
                                            }
                                        }))}
                                    />
                                    {formState?.errors?.AgentCode && <div className="text-danger">{formState.errors.AgentCode}</div>}
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label className="form-label">Reference</label>
                                    <select
                                        name="ReferenceId"
                                        className="form-select"
                                        value={formState?.formData?.ReferenceId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                ReferenceId: e.target.value
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Reference</option>
                                        {referenceTypes.map((code) => (
                                            <option key={code.reference_id} value={code.reference_id}>
                                                {code.reference_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.ReferenceId && <div className="text-danger">{formState.errors?.ReferenceId}</div>}
                                    {!showAddReferenceTypeField && <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddReferenceClick}
                                        >
                                            Add Reference
                                        </button>
                                    </div>}
                                </div>
                                <div className="col-md-6 mb-4">
                                    {showAddReferenceTypeField &&
                                        <div>
                                            <label className="form-label">Reference</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="New Reference"
                                                value={newReferenceTypeName}
                                                onChange={(e) => setNewReferenceTypeName(e.target.value)}
                                            />
                                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={handleSaveNewReferenceType}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success ms-2"
                                                    onClick={() => setShowAddReferenceTypeField(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    )}
                    {currentStep === 6 && (
                        <>
                            <div className="mediclaim-h1">
                                <h3>Previous Policy Details</h3>
                            </div>
                            <div className="row">
                                {/* Policy Number */}
                                <div className="col-md-6 mb-4">
                                    <label>Policy Number</label>
                                    <input
                                        type="text"
                                        name="PolicyNumber"
                                        className="form-control"
                                        placeholder="Policy Number"
                                        value={formState?.formData?.previousPolicy?.PolicyNumber}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyNumber: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.PolicyNumber && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyNumber}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Type</label>
                                    <select
                                        name="PolicyTypeId"
                                        className="form-select"
                                        value={formState?.formData?.previousPolicy?.PolicyTypeId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyTypeId: e.target.value
                                                }
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Policy Type</option>
                                        {policyTypes.map((code) => (
                                            <option key={code.policy_type_id} value={code.policy_type_id}>
                                                {code.policy_type_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.previousPolicy?.PolicyTypeId && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyTypeId}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Company Name</label>
                                    <select
                                        name="CompanyId"
                                        className="form-select"
                                        value={formState?.formData?.previousPolicy?.CompanyId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    CompanyId: e.target.value
                                                }
                                            }
                                        }))}
                                    >
                                        <option value="" disabled>Select Company Name</option>
                                        {companyTypes.map((code) => (
                                            <option key={code.company_id} value={code.company_id}>
                                                {code.company_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.previousPolicy?.CompanyId && <div className="text-danger">{formState.errors?.previousPolicy?.CompanyId}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Tenure</label>
                                    <input
                                        type="number"
                                        name="RunningPolicyTenure"
                                        className="form-control"
                                        placeholder="Policy Tenure"
                                        value={formState?.formData?.previousPolicy?.PolicyTenure}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyTenure: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.PolicyTenure && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyTenure}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Premium Amount</label>
                                    <input
                                        type="number"
                                        name="RunningPremiumAmount"
                                        className="form-control"
                                        placeholder="Premium Amount"
                                        value={formState?.formData?.previousPolicy?.PremiumAmount}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PremiumAmount: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.PremiumAmount && <div className="text-danger">{formState.errors?.previousPolicy?.PremiumAmount}</div>}
                                </div>


                                {/* From Date */}
                                <div className="col-md-6 mb-4">
                                    <label>From</label>
                                    <input
                                        type="date"
                                        name="poifrom"
                                        className="form-control"
                                        value={formState.formData?.previousPolicy?.PolicyFrom}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyFrom: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.PolicyFrom && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyFrom}</div>}
                                </div>

                                {/* To Date */}
                                <div className="col-md-6 mb-4">
                                    <label>To</label>
                                    <input
                                        type="date"
                                        name="poito"
                                        className="form-control"
                                        value={formState.formData?.previousPolicy?.PolicyTo}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyTo: e.target.value,
                                                    ExpiryDate: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.PolicyTo && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyTo}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>NCB</label>
                                    <input
                                        type="text"
                                        name="NCB"
                                        className="form-control"
                                        placeholder="NCB"
                                        value={formState?.formData?.previousPolicy?.NCB}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    NCB: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.NCB && <div className="text-danger">{formState.errors?.previousPolicy?.NCB}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>IDV</label>
                                    <input
                                        type="text"
                                        name="IDV"
                                        className="form-control"
                                        placeholder="IDV"
                                        value={formState?.formData?.previousPolicy?.IDV}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    IDV: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.IDV && <div className="text-danger">{formState.errors?.previousPolicy?.IDV}</div>}
                                </div>
                                <div className="col-md-6 mb-4">
                                    <label className="form-label">Claim</label>
                                    <select
                                        name="claim"
                                        className="form-select"
                                        value={formState?.formData?.previousPolicy?.claim}
                                        onChange={(e) => {
                                            const claim = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    previousPolicy: {
                                                        ...prevState.formData?.previousPolicy,
                                                        claim: claim
                                                    }
                                                }
                                            }));
                                        }}
                                    >
                                        <option value="" disabled>Select Claim</option>
                                        {["Yes", "No"].map((code) => (
                                            <option key={code} value={code}>
                                                {code}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.previousPolicy?.claim && <div className="text-danger">{formState.errors?.previousPolicy?.claim}</div>}
                                </div>


                                <div className="col-md-6 mb-4">
                                    <label>Policy Issued Date</label>
                                    <input
                                        type="date"
                                        name="PolicyIssuedDate"
                                        className="form-control"
                                        value={formState?.formData?.previousPolicy?.PolicyIssuedDate}
                                        onChange={(e) => {
                                            const policyIssuedDate = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    previousPolicy: {
                                                        ...prevState.formData?.previousPolicy,
                                                        PolicyIssuedDate: policyIssuedDate
                                                    }
                                                }
                                            }));
                                        }}
                                    />
                                    {formState.errors?.previousPolicy?.PolicyIssuedDate && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyIssuedDate}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Expiry Date</label>
                                    <input
                                        type="date"
                                        name="ExpiryDate"
                                        className="form-control"
                                        value={formState?.formData?.previousPolicy?.ExpiryDate}
                                        disabled
                                    />
                                    {formState.errors?.previousPolicy?.ExpiryDate && <div className="text-danger">{formState.errors?.previousPolicy?.ExpiryDate}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Vendor</label>
                                    <input
                                        type="text"
                                        name="Vendor"
                                        className="form-control"
                                        placeholder="Vendor"
                                        value={formState?.formData?.previousPolicy?.Vendor}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    Vendor: e.target.value
                                                }
                                            }
                                        }))}
                                    />
                                    {formState.errors?.previousPolicy?.Vendor && <div className="text-danger">{formState.errors?.previousPolicy?.Vendor}</div>}
                                </div>

                                <div className="col-md-6 mb-4">
                                    <label>Policy Plan Type</label>
                                    <select
                                        name="PolicyPlanTypeId"
                                        className="form-select"
                                        value={formState?.formData?.previousPolicy?.PolicyPlanTypeId}
                                        onChange={(e) => setFormState(prevState => ({
                                            ...prevState,
                                            formData: {
                                                ...prevState.formData,
                                                previousPolicy: {
                                                    ...prevState.formData?.previousPolicy,
                                                    PolicyPlanTypeId: e.target.value
                                                }
                                            }

                                        }))}
                                    >
                                        <option value="" disabled>Select Policy Plan Type</option>
                                        {policyPlans.map((code) => (
                                            <option key={code.policy_plan_id} value={code.policy_plan_id}>
                                                {code.policy_name}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.previousPolicy?.PolicyPlanTypeId && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyPlanTypeId}</div>}
                                </div>
                                {formState?.formData?.nomineeRadio === "Corporate" && <div className="col-md-6 mb-4">
                                    <label className="form-label">Nominee Details</label>
                                    <select
                                        name="isNomineeFlag"
                                        className="form-select"
                                        value={formState?.formData?.previousPolicy?.isNomineeFlag}
                                        onChange={(e) => {
                                            const isNomineeFlag = e.target.value;
                                            setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    previousPolicy: {
                                                        ...prevState.formData?.previousPolicy,
                                                        isNomineeFlag: isNomineeFlag
                                                    }
                                                }
                                            }));
                                        }}
                                    >
                                        <option value="" disabled>Noimnee Details</option>
                                        {["Yes", "No"].map((code) => (
                                            <option key={code} value={code}>
                                                {code}
                                            </option>
                                        ))}
                                    </select>
                                    {formState.errors?.previousPolicy?.isNomineeFlag && <div className="text-danger">{formState.errors?.previousPolicy?.isNomineeFlag}</div>}
                                </div>}

                                {formState.formData?.previousPolicy?.isNomineeFlag === "Yes" || formState.formData?.nomineeRadio === "Individule" && <>
                                    <div className="col-md-6 mb-4">
                                        <label>Nominee Name</label>
                                        <input
                                            type="text"
                                            name="NomineeName"
                                            className="form-control"
                                            placeholder="Nominee Name"
                                            value={formState?.formData?.previousPolicy?.NomineeName}
                                            onChange={(e) => setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    previousPolicy: {
                                                        ...prevState.formData?.previousPolicy,
                                                        NomineeName: e.target.value
                                                    }
                                                }
                                            }))}
                                        />
                                        {formState.errors?.previousPolicy?.NomineeName && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeName}</div>}
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label>Nominee Relation</label>
                                        <input
                                            type="text"
                                            name="NomineeRelation"
                                            className="form-control"
                                            placeholder="Nominee Relation"
                                            value={formState?.formData?.previousPolicy?.NomineeRelation}
                                            onChange={(e) => setFormState(prevState => ({
                                                ...prevState,
                                                formData: {
                                                    ...prevState.formData,
                                                    previousPolicy: {
                                                        ...prevState.formData?.previousPolicy,
                                                        NomineeRelation: e.target.value
                                                    }
                                                }
                                            }))}
                                        />
                                        {formState.errors?.previousPolicy?.NomineeRelation && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeRelation}</div>}
                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <label>Nominee DOB</label>
                                        <input
                                            type="date"
                                            name="NomineeDob"
                                            className="form-control"
                                            placeholder="Nominee DOB"
                                            value={formState?.formData?.previousPolicy?.NomineeDob}
                                            onChange={handleNomineeDatePreviousChange}
                                        />
                                        {formState.errors?.previousPolicy?.NomineeDob && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeDob}</div>}
                                    </div>

                                    <div className="col-md-6 mb-4">
                                        <label>Nominee Age</label>
                                        <input
                                            type="text"
                                            name="RunningNomineeAge"
                                            className="form-control"
                                            placeholder="Nominee Age"
                                            value={formState?.formData?.previousPolicy?.NomineeAge}
                                            readOnly
                                        />
                                        {formState.errors?.previousPolicy?.NomineeAge && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeAge}</div>}
                                    </div>

                                </>}


                                <div className="col-md-6 mb-4">
                                    <label>Upload Policy PDF</label>
                                    {initialData?.previousPolicy?.CurrentPolicyFile && (
                                        <div>
                                            <a
                                                href={initialData?.previousPolicy?.CurrentPolicyFile}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent default link behavior
                                                    redirectpageproduct(initialData?.previousPolicy?.CurrentPolicyFile); // Use the redirectpage function
                                                }}
                                                style={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                View Existing PDF {initialData?.previousPolicy?.CurrentPolicyFile.slice(0, 5) + '...'}
                                            </a>
                                        </div>
                                    )}
                                    <input
                                        type="file"
                                        name="CurrentPolicyFile"
                                        className="form-control"
                                        accept="application/pdf"
                                        onChange={handleFilePreviousChange}
                                    />
                                    {formState?.formData?.previousPolicy?.CurrentPolicyFile && (
                                        <p>Selected File: {formState.formData.previousPolicy.CurrentPolicyFile.name}</p>
                                    )}
                                    {formState.errors?.previousPolicy?.CurrentPolicyFile && <div className="text-danger">{formState.errors?.previousPolicy?.CurrentPolicyFile}</div>}
                                </div>

                            </div>
                        </>
                    )}

                    <div className="button-group d-flex justify-content-between">
                        {currentStep > 1 && (
                            <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handlePrev(); }}>Back</button>
                        )}
                        {(currentStep < 5 || (currentStep === 5 && formState?.formData?.policyRadio !== "Fresh" && formState?.formData?.policyRadio !== "")) ? (
                            <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handleNext(); }}>Next</button>
                        ) : (
                            !view && (((formState?.formData?.policyRadio === "Fresh" || formState?.formData?.policyRadio === "") && currentStep === 5) || (formState?.formData?.policyRadio !== "Fresh" && currentStep === 6)) && (
                                <button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            )
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};


export default VehiclePopup;
