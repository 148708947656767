import React, { useState, useEffect } from "react";
import "./Table.css";
import { DOWNLOAD_URL, formatDate } from "../serviceAPI/userAPI";
import Search from "./Search";
import { useNavigate } from "react-router-dom";

const MediclaimRenewalTable = ({
    headings,
    data,
    handleEdit,
    handleView,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    sortColumn,
    sortDirection,
    handleSort,
    setItemsPerPage,
}) => {
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate()

    useEffect(() => {
        filterData(searchTerm);
    }, [data, searchTerm]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const filterData = (searchTerm) => {
        const newData = data.filter((item) =>
            Object.values(item).some((val) =>
                String(val).toLowerCase().includes(searchTerm)
            )
        );
        setFilteredData(newData);
    };

    const clearSearch = () => {
        setSearchTerm("");
        setFilteredData(data);
    };

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortColumn) {
            const aValue = String(a[sortColumn]).toLowerCase();
            const bValue = String(b[sortColumn]).toLowerCase();
            if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
            if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const handlePopupOpen = (item) => {
        console.log(item)
        if(item.id){
            localStorage.setItem('isRenew',true)
            localStorage.setItem('MediclaimID',item.id)
            navigate('/mediclaim')
        }
       
    }

    const getVisiblePageNumbers = () => {
        const pages = [];
        let startPage, endPage;

        if (totalPages <= 3) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage >= totalPages - 1) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    id={1}
                    onClick={handleClick}
                    className={`page-number ${currentPage === 1 ? "active" : ""}`}
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(
                    <span key="start-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    id={i}
                    onClick={handleClick}
                    className={`page-number ${currentPage === i ? "active" : ""}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <span key="end-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
            pages.push(
                <button
                    key={totalPages}
                    id={totalPages}
                    onClick={handleClick}
                    className={`page-number ${currentPage === totalPages ? "active" : ""
                        }`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };


    return (
        <div>
            <div className="table-container">
                <div className="table-h">
                    <div className="table-h-c">
                        <Search
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                            clearSearch={clearSearch}
                        />
                    </div>
                    <div className="table-h-c"></div>
                    <div className="show-by table-h-c">
                        <label>Show by Rows:</label>
                        <select
                            value={itemsPerPage}
                            onChange={(e) => {
                                setItemsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                <div style={{ width: '100%', overflow: 'auto' }}>
                    <table className="universal-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                {headings.map((heading, index) => (
                                    <th key={index} onClick={() => handleSort(heading.key)}>
                                        {heading.head}{" "}
                                        {sortColumn === heading.key
                                            ? sortDirection === "asc"
                                                ? "▲"
                                                : "▼"
                                            : null}
                                    </th>
                                ))}
                                <th style={{ textAlign: 'center' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData && currentData.length ? (
                                currentData.map((item, index) => {
                                    const expiryDate = item?.runningPolicy?.ExpiryDate
                                    const PolicyIssuedDate = item?.runningPolicy?.PolicyIssuedDate
                                    const PolicyNumber = item?.runningPolicy?.PolicyNumber
                                    return (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            {headings &&
                                                headings.map((heading, hIndex) => (
                                                    <td className="show-by" key={hIndex}>
                                                        {
                                                            heading.key === "renew" ? (
                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => handlePopupOpen(item)}>
                                                                    <span className={`status-box status-cancel`}>
                                                                        {"Renew"}
                                                                    </span>
                                                                </div>) : heading.key === "runningPolicy.ExpiryDate" ? formatDate(expiryDate) : heading.key === "runningPolicy.PolicyNumber" ? PolicyNumber : heading.key === "runningPolicy.IssueDate" ? formatDate(PolicyIssuedDate) : (
                                                                    item[heading.key]
                                                                )
                                                        }
                                                    </td>
                                                ))}

                                            <td>
                                                <div style={{ padding: "0px", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                    <img
                                                        className="table-view"
                                                        src="/Assets/view-icon.png"
                                                        alt="view"
                                                        onClick={() => handleView(item)} // Trigger view
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={headings.length + 2}>No record found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {filteredData && (
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        {getVisiblePageNumbers()}
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediclaimRenewalTable;
