import React, { useState } from 'react';
import Sidebar from './Sidebar';
import './DashboardLayout.css';
import Header from '../pages/Header';

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="dashboard-layout">
      <div className={`dashboard-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <main className={`main-content ${isSidebarOpen ? 'expanded' : 'collapsed'}`}>
          <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
          <div className='p-3'>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
