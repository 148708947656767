import React, { useState, useEffect } from 'react';
import './popup-u.css';
import Cookies from 'js-cookie';
import { useToaster } from './Toaster';
import { addUpdateLoanConfiguration, addUpdateLoanDisburse } from '../serviceAPI/userAPI';

const LoanConfigurationnPopup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const addToast = useToaster();
  const [formState, setFormState] = useState({
    dropdownValue: '',
    file: null,
  });


  const user = Cookies.get('user') && JSON.parse(Cookies.get('user')) || '';

  useEffect(() => {
    if (initialData) {
      console.log(initialData,'initialData')
      setFormState({
        dropdownValue: initialData.categoryname || '',
        file: null
      });
    } else {
      setFormState({
        dropdownValue: '',
        file: null
      });
    }
  }, [initialData]);

  const handleDropdownChange = (e) => {
    const { value } = e.target;
    console.log(value)
    setFormState(prevState => ({
      ...prevState,
      dropdownValue: value,
    }));
  };

  console.log(formState)

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setFormState(prevState => ({
        ...prevState,
        file: file
      }));
    } else {
      alert('Please upload a PDF file.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formState.file) {
      alert('File is required.');
      return;
    }
    console.log(initialData, 'initialData')

    const formData = new FormData();
    if (initialData?.config_id) {
      formData.append('user_id', initialData?.config_id);
      formData.append('categoryname', formState.dropdownValue);
      if (formState.file) {
        formData.append('pdfFile', formState.file);
      }
      console.log(formData, 'formData', formState)

      let response = await addUpdateLoanConfiguration(formData, addToast);
      if (response) {
        fetchApi();
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Disburse' : 'Add Disburse'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <label>Dropdown</label>
              <select value={formState.dropdownValue} className='form-select' onChange={handleDropdownChange} required>
                <option key={'select'} value={''}>{'Select Service'}</option>
                <option key={1} value={"Home"}>{'Home'}</option>
                <option key={2} value={"Car"}>{'Car'}</option>
                <option key={3} value={"NRP"}>{'NRP'}</option>
                <option key={4} value={"Personal"}>{'Personal'}</option>
                <option key={5} value={"Buissness"}>{'Buissness'}</option>
                <option key={6} value={"CC && OD"}>{'CC && OD'}</option>
              </select>
            </div>
            <div className='col-md-6 mb-4'>
              <label>Upload PDF</label>
              <input type="file" className='form-control' accept="application/pdf" onChange={handleFileChange} />
            </div>
          </div>
          <button className="btn btn-blue" type="submit">{initialData ? 'Update' : 'Add'}</button>
        </form>
      </div>
    </div>
  );
};

export default LoanConfigurationnPopup;
