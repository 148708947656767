import React, { useEffect, useState } from 'react';
import './popup-u.css';
import { addMediclaimUser, DOWNLOAD_URL, getAllMediclaimCompany, getAllMediclaimProduct, updateMediclaimUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';

const MediclaimPopup = ({ isOpen, onClose, fetchApi, initialData, view }) => {
  const addToast = useToaster()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [productType, setProductType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [formState, setFormState] = useState({
    formData: {
      Name: '',
      MobileNumber: '',
      Email: '',
      RadioButton: '', // Fresh, Renew, Portability
      policyRadio: '',
      DateOfBirth: '',
      Age: '',
      Gender: '',
      RelationshipWithPolicyHolder: '',
      InsuredPersonName: '',
      SumInsured: '',
      NoClaimBonus: '',
      PreExistingIllness: '',

      // Running Policy Data
      runningPolicy: {
        Zone: '',
        PolicyNumber: '',
        PolicyTenure: '',
        PremiumAmount: '',
        NomineeName: '',
        NomineeRelation: '',
        NomineeAge: '',
        NomineeDob: '',
        PolicyTo: '',
        PolicyFrom: '',
        PolicyPlanType: '',
        AdditionalSumInsured: '',
        PreviousPolicyFlag: '',
        AddOnCover: '',
        ExpiryDate: '',
        CurrentPolicyFile: '',
        PolicyIssuedDate: '',
      },

      // Previous Policy Data (For Renewals and Portability)
      previousPolicy: {
        PolicyTo: '',
        PolicyFrom: '',
        Zone: '',
        RenewDate: '',
        PolicyNumber: '',
        PolicyTenure: '',
        PremiumAmount: '',
        NomineeName: '',
        NomineeRelation: '',
        NomineeDob: '',
        NomineeAge: '',
        PdfFile: '',
        ClaimExpireInPolicy: '',
        PreviousPolicyNumber: '',
        CompanyName: '',
        ClaimStatementPDFfile: '',
      },

      // Last Policy Data (Historical Reference)
      AgentName: '',
      AgentCode: '',
      AgentContactNumber: '',
      ProductName: '',
      CompanyName: '',

    },
    familyMembers: [
      {
        DateOfBirth: '',
        Age: '',
        Gender: '',
        RelationshipWithPolicyHolder: '',
        FamilyName: '',
      },
    ],
    errors: {},
  });


  useEffect(() => {
    if (initialData && initialData.id) {
      const familyMembers = initialData.familymembers?.map(item => ({
        DateOfBirth: item.DateOfBirth ? item.DateOfBirth.slice(0, 10) : "",
        Age: item.Age || 0,
        Gender: item.Gender || "",
        RelationshipWithPolicyHolder: item.RelationshipWithPolicyHolder || "",
        FamilyName: item.FamilyName || "",
      })) || [];

      console.log(initialData?.familymembers, 'familyMembers')

      const {
        medicliam_type,
        medicliam_policy_type,
        dob,
        age,
        gender,
        relationshipWithPolicyHolder,
        insuredPersonName,
        sumInsured,
        noClaimBonus,
        preExistingIllness,
        nomineeName,
        previousPolicy,
        agentName,
        agentCode,
        agentContactNumber,
        mediclaim_product_id,
        mediclaim_company_id,
        runningPolicy
      } = initialData;

      setFormState({
        formData: {
          Name: initialData['user.username'] || '',
          MobileNumber: initialData['user.mobileNumber'] || '',
          Email: initialData['user.email'] || '',
          RadioButton: medicliam_type || '',
          policyRadio: medicliam_policy_type || '',
          DateOfBirth: dob ? dob.slice(0, 10) : '',
          Age: age || 0,
          Gender: gender || '',
          RelationshipWithPolicyHolder: relationshipWithPolicyHolder || '',
          InsuredPersonName: insuredPersonName || '',
          SumInsured: sumInsured || '',
          NoClaimBonus: noClaimBonus || '',
          PreExistingIllness: preExistingIllness || '',
          NomineeName: nomineeName || '',

          runningPolicy: {
            Zone: runningPolicy?.Zone || '',
            PolicyNumber: runningPolicy?.PolicyNumber || '',
            PolicyTenure: runningPolicy?.PolicyTenure,
            PremiumAmount: runningPolicy?.PremiumAmount,
            AdditionalSumInsured: runningPolicy?.AdditionalSumInsured || 1,
            AddOnCover: runningPolicy?.AddOnCover || '',
            NomineeName: runningPolicy?.NomineeName || '',
            NomineeRelation: runningPolicy?.NomineeRelation || '',
            PolicyPlanType: runningPolicy?.PolicyPlanType || '',
            NomineeAge: runningPolicy?.NomineeAge,
            NomineeDob: runningPolicy?.NomineeDob && runningPolicy?.NomineeDob.slice(0, 10) || '',
            PolicyIssuedDate: runningPolicy?.PolicyIssuedDate && runningPolicy?.PolicyIssuedDate.slice(0, 10) || '',
            ExpiryDate: runningPolicy?.ExpiryDate && runningPolicy?.ExpiryDate.slice(0, 10) || '',
            PreviousPolicyFlag: runningPolicy?.PreviousPolicyFlag && runningPolicy?.PreviousPolicyFlag || '',
            PolicyFrom: runningPolicy?.PolicyFrom ? runningPolicy?.PolicyFrom.slice(0, 10) : '',
            PolicyTo: runningPolicy?.PolicyTo ? runningPolicy?.PolicyTo.slice(0, 10) : '',
            CurrentPolicyFile: runningPolicy?.CurrentPolicyFile || '',
          },

          previousPolicy: {
            Zone: previousPolicy?.Zone || '',
            PolicyNumber: previousPolicy?.PolicyNumber || '',
            PolicyFrom: previousPolicy?.PolicyFrom ? previousPolicy?.PolicyFrom.slice(0, 10) : '',
            PolicyTo: previousPolicy?.PolicyTo ? previousPolicy?.PolicyTo.slice(0, 10) : '',
            RenewDate: previousPolicy?.RenewDate ? previousPolicy?.RenewDate.slice(0, 10) : '',
            PolicyTenure: previousPolicy?.PolicyTenure || '',
            PremiumAmount: previousPolicy?.PremiumAmount || '',
            PdfFile: previousPolicy?.PdfFile || '',
            ClaimStatementPDFfile: previousPolicy?.ClaimStatementPDFfile || '',
            ClaimExpireInPolicy: previousPolicy?.ClaimExpireInPolicy || '',
            NomineeName: previousPolicy?.NomineeName || '',
            NomineeRelation: previousPolicy?.NomineeRelation || '',
            PreviousPolicyNumber: previousPolicy?.PreviousPolicyNumber || '',
            CompanyName: previousPolicy?.CompanyName || '',
            NomineeAge: previousPolicy?.NomineeAge,
            NomineeDob: previousPolicy?.NomineeDob && previousPolicy?.NomineeDob.slice(0, 10) || '',
          },
          AgentName: agentName || '',
          AgentCode: agentCode || '',
          AgentContactNumber: agentContactNumber || '',
          ProductName: mediclaim_product_id || '',
          CompanyName: mediclaim_company_id || '',
        },
        familyMembers,
      });

      setCompanyType(initialData.mediclaim_company_id || '');
      if (initialData.mediclaim_company_id) {
        getProductData(initialData.mediclaim_company_id);
        if (initialData?.previousPolicy?.mediclaim_product_id) {
          setProductType(initialData?.previousPolicy?.mediclaim_product_id);
        }
      }

      let isRenew = localStorage.getItem('isRenew') || '';
      let MediclaimID = localStorage.getItem('MediclaimID') || '';
      console.log(isRenew, 'isRenew')
      if (isRenew && MediclaimID) {
        setFormState(prevState => {
          console.log(initialData)

          let updatedPreviousPolicy = {
            PolicyNumber: runningPolicy.PolicyNumber || '',
            Zone: runningPolicy.Zone || '',
            PolicyTenure: runningPolicy.PolicyTenure || '',
            PremiumAmount: runningPolicy.PremiumAmount || '',
            NomineeName: runningPolicy.NomineeName || '',
            NomineeRelation: runningPolicy.NomineeRelation || '',
            NomineeAge: runningPolicy.NomineeAge || '',
            NomineeDob: runningPolicy.NomineeDob || '',
            PolicyFrom: runningPolicy.PolicyFrom || '',
            PolicyTo: runningPolicy.PolicyTo || '',
            RenewDate: '',
            PdfFile: '',
            ClaimExpireInPolicy: '',
            PreviousPolicyNumber: '',
            CompanyName: '',
            ClaimStatementPDFfile: '',
          };
          setProductType('')


          return {
            ...prevState,
            formData: {
              ...prevState.formData,
              policyRadio: medicliam_policy_type == 'Fresh' ? "Renewal" : medicliam_policy_type == 'Renewal' ? "Renewal" : "Portability",
              previousPolicy: updatedPreviousPolicy
            }
          };
        });

        // **Ensure localStorage is cleared only after state update**
        setTimeout(() => {
          localStorage.removeItem('isRenew');
          localStorage.removeItem('MediclaimID');
        }, 1000);
      }
    }
  }, [initialData]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setFormState(prevState => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          runningPolicy: {
            ...prevState.formData?.runningPolicy,
            CurrentPolicyFile: file,
          },
        },
      }));
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  useEffect(() => {
    getCompanyData()
  }, []);

  const validateForm = () => {
    const errors = { runningPolicy: {}, previousPolicy: {} };
    const { formData, familyMembers } = formState;

    // Helper function for common validations
    const isValidNumber = (value) => !isNaN(value) && value >= 0;
    const isValidNumber1 = (value) => !isNaN(value) && value >= 0;
    const isValidDate = (date) => date && !isNaN(new Date(date).getTime());
    console.log(currentStep, 'sddssd')

    // Step 1: Basic Information Validation
    if (currentStep === 1) {
      if (!formData.Name) errors.Name = 'Name is required';
      if (!formData.Email || !/\S+@\S+\.\S+/.test(formData.Email)) errors.Email = 'Valid Email is required';
      if (!formData.MobileNumber || formData.MobileNumber.length < 10) errors.MobileNumber = 'Valid Mobile Number is required';
      if (!companyType) errors.CompanyName = 'Company Name is required';
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or  or Employee';
      if (!formData.policyRadio) errors.policyRadio = 'Please select Policy Type';
    }

    // Step 2: Policyholder & Family Validation
    else if (currentStep === 2) {
      if (!formData.RadioButton) errors.RadioButton = 'Please select Family or Individual or Employee';

      if(formData.RadioButton === "Employee" && !formData.InsuredPersonName){
        errors.InsuredPersonName = 'Insured Person Name is required';
      }

      // Common Validations for Both Individual & Family
      if (!formData.DateOfBirth) errors.DateOfBirth = 'Date of Birth is required';
      if (!formData.Gender) errors.Gender = 'Gender is required';
      if (!formData.RelationshipWithPolicyHolder) errors.RelationshipWithPolicyHolder = 'Relationship with Policy Holder is required';
      if (!formData.PreExistingIllness) errors.PreExistingIllness = 'Pre-existing Illness is required';
      if (!isValidNumber(formData.SumInsured)) errors.SumInsured = 'Valid Sum Insured is required';
      if (!isValidNumber1(formData.NoClaimBonus)) errors.NoClaimBonus = 'Valid No Claim Bonus is required';

      // Additional validation for Family members
      if (formData.RadioButton === 'Family') {
        familyMembers.forEach((member, index) => {
          if (!member.DateOfBirth) errors[`member${index}_DateOfBirth`] = `Date of Birth is required for family member ${index + 1}`;
          if (!member.Gender) errors[`member${index}_Gender`] = `Gender is required for family member ${index + 1}`;
          if (!member.RelationshipWithPolicyHolder) errors[`member${index}_RelationshipWithPolicyHolder`] = `Relationship with Policy Holder is required for family member ${index + 1}`;
          if (!member.FamilyName) errors[`member${index}_FamilyName`] = `Family member name is required for family member ${index + 1}`;

        });
      }
    }

    // Step 3: Policy Dates & Insurance Period
    else if (currentStep === 3) {
      if (!isValidDate(formData.runningPolicy.PolicyFrom)) errors.runningPolicy.PolicyFrom = 'Period of Insurance "From" date is required';
      if (!isValidDate(formData.runningPolicy.PolicyTo)) errors.runningPolicy.PolicyTo = 'Period of Insurance "To" date is required';

      if (
        isValidDate(formData.runningPolicy.PolicyFrom) &&
        isValidDate(formData.runningPolicy.PolicyTo) &&
        new Date(formData.runningPolicy.PolicyTo) <= new Date(formData.runningPolicy.PolicyFrom)
      ) {
        errors.runningPolicy.PolicyTo = 'The "To" date must be later than the "From" date';
      }

      if (!isValidNumber(formData.runningPolicy.AdditionalSumInsured)) errors.runningPolicy.AdditionalSumInsured = 'Valid Additional Sum Insured is required';
      if (!isValidDate(formData.runningPolicy.PolicyIssuedDate)) errors.runningPolicy.PolicyIssuedDate = 'Policy Issued Date is required';

      if (
        !isValidDate(formData.runningPolicy.PolicyIssuedDate)
      ) {
        errors.runningPolicy.PolicyIssuedDate = 'Policy Issued Date is not valid';
      }

      if (!formData.runningPolicy.PolicyPlanType) errors.runningPolicy.PolicyPlanType = 'Policy Plan Type is required';
      if (!formData.runningPolicy.Zone) errors.runningPolicy.Zone = 'Zone is required';
      if (!formData.runningPolicy.PolicyNumber) errors.runningPolicy.PolicyNumber = 'Policy Number is required';
      if (!formData.runningPolicy.PolicyTenure) errors.runningPolicy.PolicyTenure = 'Policy Tenure is required';
      if (!isValidNumber(formData.runningPolicy.PremiumAmount)) errors.runningPolicy.PremiumAmount = 'Valid Premium Amount is required';
      if (!formData.runningPolicy.NomineeName) errors.runningPolicy.NomineeName = 'Nominee Name is required';
      if (!formData.runningPolicy.NomineeRelation) errors.runningPolicy.NomineeRelation = 'Nominee Relation is required';
      // if (!isValidNumber(formData.runningPolicy.NomineeAge)) errors.runningPolicy.NomineeAge = 'Valid Nominee Age is required';
      if (!isValidDate(formData.runningPolicy.NomineeDob)) errors.runningPolicy.NomineeDob = 'Valid Nominee Date of Birth is required';
      if (!formData.runningPolicy.PreviousPolicyFlag && formData.policyRadio === 'Fresh') errors.runningPolicy.PreviousPolicyFlag = 'Previous Policy Flag is required';
      if (!formData.runningPolicy.AdditionalSumInsured) errors.runningPolicy.AdditionalSumInsured = 'Additional Sum Insured is required';
      if (!formData.runningPolicy.AddOnCover) errors.runningPolicy.AddOnCover = 'Add-On Cover information is required';
      if (!isValidDate(formData.runningPolicy.ExpiryDate)) errors.runningPolicy.ExpiryDate = 'Valid Expiry Date is required';
      if (!formData.runningPolicy.CurrentPolicyFile) errors.runningPolicy.CurrentPolicyFile = 'Current Policy File is required';
    }

    // Step 4: Policy Details Validation
    else if (currentStep === 4) {
      if (!formData.previousPolicy.PolicyNumber) errors.previousPolicy.PolicyNumber = 'Previous Policy Number is required';
      if (!isValidDate(formData.previousPolicy.PolicyFrom)) errors.previousPolicy.PolicyFrom = 'Previous Policy "From" date is required';
      if (!isValidDate(formData.previousPolicy.PolicyTo)) errors.previousPolicy.PolicyTo = 'Previous Policy "To" date is required';
      if (
        isValidDate(formData.previousPolicy.PolicyFrom) &&
        isValidDate(formData.previousPolicy.PolicyTo) &&
        new Date(formData.previousPolicy.PolicyTo) <= new Date(formData.previousPolicy.PolicyFrom)
      ) {
        errors.runningPolicy.PolicyTo = 'The "To" date must be later than the "From" date';
      }
      if (!formData.previousPolicy.Zone) errors.previousPolicy.Zone = 'Previous Policy Zone is required';
      if (!isValidNumber(formData.previousPolicy.PolicyTenure)) errors.previousPolicy.PolicyTenure = 'Valid Previous Policy Tenure is required';
      if (!isValidNumber(formData.previousPolicy.PremiumAmount)) errors.previousPolicy.PremiumAmount = 'Valid Previous Premium Amount is required';
      if (!formData.previousPolicy.NomineeName) errors.previousPolicy.NomineeName = 'Previous Nominee Name is required';
      if (!formData.previousPolicy.NomineeRelation) errors.previousPolicy.NomineeRelation = 'Previous Nominee Relation is required';
      if (!isValidDate(formData.previousPolicy.NomineeDob)) errors.previousPolicy.NomineeDob = 'Valid Nominee Date of Birth is required';
      if (!isValidDate(formData.previousPolicy.RenewDate)) errors.previousPolicy.RenewDate = 'Valid Previous Policy Renew Date is required';
      if (!formData.previousPolicy.PdfFile) errors.previousPolicy.PdfFile = 'Previous Policy PDF file is required';
      if (!formData.previousPolicy.PreviousPolicyNumber) errors.previousPolicy.PreviousPolicyNumber = 'Previous Policy Number is required';
      if (!formData.previousPolicy.ClaimExpireInPolicy) errors.previousPolicy.ClaimExpireInPolicy = 'Claim Expiry in Previous Policy is required';
      if (!formData.previousPolicy.CompanyName) errors.previousPolicy.CompanyName = 'Company Name in Previous Policy is required';

      console.log(formData.previousPolicy.ClaimExpireInPolicy)
      if (formData.previousPolicy.ClaimExpireInPolicy === 'Yes') {
        if (!formData.previousPolicy.ClaimStatementPDFfile) errors.previousPolicy.ClaimStatementPDFfile = 'Claim Statement PDF in Previous Policy is required';
      }

      if (!productType) errors.ProductName = 'Product Name is required';
    }

    // Step 5: Agent Information Validation
    else if (currentStep === 5) {
      if (!formData.AgentName) errors.AgentName = 'Agent Name is required';
      if (!formData.AgentContactNumber || formData.AgentContactNumber.length < 10) errors.AgentContactNumber = 'Valid Agent Contact Number is required';
      if (!formData.AgentCode) errors.AgentCode = 'Agent Code is required';
    }

    // Update errors in the state
    setFormState((prevState) => ({
      ...prevState,
      errors,
    }));
    console.log(errors, 'eee')

    const filteredErrors = Object.keys(errors).filter(
      key => key !== "runningPolicy" && key !== "previousPolicy"
    );

    const isValid = filteredErrors.length === 0 &&
      Object.keys(errors.runningPolicy).length === 0 &&
      Object.keys(errors.previousPolicy).length === 0;
    // Return true if no errors
    return isValid;
  };


  const getProductData = async (id) => {
    let obj = {
      mediclaim_company_id: id
    }
    const roleData = await getAllMediclaimProduct(obj);
    if (roleData?.data && roleData?.data?.length) {
      setProductData(roleData?.data)
    } else {
      setProductData([])
    }
  }

  const redirectpageproduct = (pdfPath) => {
    // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
    const file_path = DOWNLOAD_URL + pdfPath;
    console.log(file_path, "file");

    // Create a temporary <a> element
    const a = document.createElement("a");
    a.href = file_path;
    a.target = "_blank"; // Open in a new tab
    a.download = file_path; // Extract filename from path

    // Trigger the click programmatically
    document.body.appendChild(a);
    a.click();

    // Remove the element after triggering
    document.body.removeChild(a);
  };
  const getCompanyData = async () => {
    const roleData = await getAllMediclaimCompany();
    if (roleData?.data && roleData?.data?.length)
      setCompanyData(roleData?.data)
  }

  const handleNext = () => {
    if (view) {
      if (currentStep < 5) {
        if (currentStep === 3 && (formState?.formData?.policyRadio === 'Fresh' && (formState.formData?.runningPolicy?.PreviousPolicyFlag == '' || formState.formData?.runningPolicy?.PreviousPolicyFlag === "No"))) {
          setCurrentStep(5);
        } else {
          setCurrentStep(currentStep + 1);
        }
      }
    } else {
      if (validateForm()) {
        console.log(currentStep, 'currentStep')
        if (currentStep < 5) {
          if (currentStep === 3 && (formState?.formData?.policyRadio === 'Fresh' && (formState.formData?.runningPolicy?.PreviousPolicyFlag == '' || formState.formData?.runningPolicy?.PreviousPolicyFlag === "No"))) {
            setCurrentStep(5);
          } else {
            setCurrentStep(currentStep + 1);
          }
        }
      }
    }

  };

  const handlePrev = () => {
    if (currentStep > 1) {
      if (currentStep === 5 && (formState?.formData?.policyRadio === 'Fresh' && (formState.formData?.runningPolicy?.PreviousPolicyFlag == '' || formState.formData?.runningPolicy?.PreviousPolicyFlag === "No"))) {
        setCurrentStep(3);
      } else {
        setCurrentStep(currentStep - 1);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent form from submitting if already submitting

    // Only submit when we're on Step 5
    if (currentStep === 5) {
      if (validateForm()) {
        // setIsSubmitting(true); // Set flag to true to prevent multiple submissions

        console.log('Form Submitted', formState.formData);

        // Validate mobile number format
        const regex = /^\d{10}$/;
        if (!regex.test(formState?.formData?.MobileNumber)) {
          alert('Mobile number is invalid');
          // setIsSubmitting(false); // Reset the flag
          return;
        }

        const formDataPDF = new FormData();
        if (formState?.formData?.runningPolicy?.CurrentPolicyFile) {
          formDataPDF.append('CurrentPolicyFile', formState?.formData?.runningPolicy?.CurrentPolicyFile);
        }
        if (formState?.formData?.previousPolicy?.ClaimStatementPDFfile && formState?.formData?.previousPolicy?.ClaimExpireInPolicy === 'Yes') {
          formDataPDF.append('ClaimStatementPDFfile', formState?.formData?.previousPolicy?.ClaimStatementPDFfile);
        }
        if (formState?.formData?.previousPolicy?.PdfFile) {
          formDataPDF.append('PdfFile', formState?.formData?.previousPolicy?.PdfFile);
        }

        // Prepare payload with form data and family members
        const payload = {
          ...formState.formData,
          familyMembers: formState.familyMembers,
          ProductName: productType,
          CompanyName: companyType,
          // formDataPDF: formDataPDF,
        };

        console.log('Payload:', payload);

        try {
          let response;
          if (initialData && initialData.id) {
            // Update existing record
            payload.user_id = initialData.user_id;
            payload.id = initialData.id;
            formDataPDF.append("data", JSON.stringify(payload));
            response = await updateMediclaimUser(formDataPDF, payload.id, addToast);
          } else {
            formDataPDF.append("data", JSON.stringify(payload));
            // Add new record
            response = await addMediclaimUser(formDataPDF, addToast);
          }

          if (response.status) {
            // Success logic
            fetchApi();
            onClose();
          }
        } catch (error) {
          console.error('Error during submission:', error);
        } finally {
          setIsSubmitting(false); // Reset the flag after submission attempt
        }
      }
    }
  };


  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        DateOfBirth: dateOfBirth,
        Age: age
      }
    }));
  };

  const handleNomineeDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        runningPolicy: {
          ...prevState.formData.runningPolicy,
          NomineeDob: dateOfBirth,
          NomineeAge: age
        }
      }
    }));
  };

  const handleNomineePreviousDateChange = (e) => {
    const dateOfBirth = e.target.value;
    const age = calculateAge(dateOfBirth);
    setFormState(prevState => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        previousPolicy: {
          ...prevState.formData.previousPolicy,
          NomineeDob: dateOfBirth,
          NomineeAge: age
        }
      }
    }));
  };
  const handlePolicyRatio = (e) => {
    const newPolicyType = e.target.value;
    setFormState(prevState => {
      let updatedPreviousPolicy = { ...prevState.formData.previousPolicy };
      console.log(initialData)

      if (initialData?.medicliam_policy_type === 'Fresh' && (newPolicyType === 'Renewal' || newPolicyType === "Portability")) {
        updatedPreviousPolicy = {
          PolicyNumber: prevState.formData.runningPolicy.PolicyNumber || '',
          Zone: prevState.formData.runningPolicy.Zone || '',
          PolicyTenure: prevState.formData.runningPolicy.PolicyTenure || '',
          PremiumAmount: prevState.formData.runningPolicy.PremiumAmount || '',
          NomineeName: prevState.formData.runningPolicy.NomineeName || '',
          NomineeRelation: prevState.formData.runningPolicy.NomineeRelation || '',
          NomineeAge: prevState.formData.runningPolicy.NomineeAge || '',
          NomineeDob: prevState.formData.runningPolicy.NomineeDob || '',
          PolicyFrom: prevState.formData.runningPolicy.PolicyFrom || '',
          PolicyTo: prevState.formData.runningPolicy.PolicyTo || '',
          RenewDate: '',
          PdfFile: '',
          ClaimExpireInPolicy: '',
          PreviousPolicyNumber: '',
          CompanyName: '',
          ClaimStatementPDFfile: '',
        };
        setProductType('')
      }

      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          policyRadio: newPolicyType,
          previousPolicy: updatedPreviousPolicy
        }
      };
    });
  }

  const handleFamilyMemberChange = (index, field, value) => {
    const updatedFamilyMembers = [...formState.familyMembers];

    // Ensure the family member at the given index exists or initialize it
    if (!updatedFamilyMembers[index]) {
      updatedFamilyMembers[index] = {
        DateOfBirth: '',
        Age: '', // Ensure Age is part of the new family member
        Gender: '',
        RelationshipWithPolicyHolder: '',
        FamilyName: ''
      };
    }

    // Handle 'DateOfBirth' specifically because we need to calculate 'Age'
    if (field === 'DateOfBirth') {
      const age = calculateAge(value); // Calculate the age based on DateOfBirth

      // If age is valid, update the 'Age' field
      if (age !== null) {
        updatedFamilyMembers[index][field] = value; // Update the DateOfBirth
        updatedFamilyMembers[index]['Age'] = age; // Update the Age
      }
    } else {
      // For all other fields, just update the value directly
      updatedFamilyMembers[index][field] = value;
    }

    // Update the formState with the modified family members
    setFormState(prevState => ({
      ...prevState,
      familyMembers: updatedFamilyMembers
    }));
  };

  const addFamilyMember = () => {
    setFormState(prevState => ({
      ...prevState,
      familyMembers: [
        ...prevState.familyMembers,
        { DateOfBirth: '', Age: '', Gender: '', RelationshipWithPolicyHolder: '', FamilyName: '' }
      ]
    }));
  };

  const removeFamilyMember = (index) => {
    const updatedFamilyMembers = [...formState.familyMembers];
    updatedFamilyMembers.splice(index, 1);
    setFormState(prevState => ({ ...prevState, familyMembers: updatedFamilyMembers }));
  };

  const onChangeHandle = (e) => {
    setProductType('');
    setFormState(prevState => ({ ...prevState, CompanyName: e.target.value }));
    getProductData(e.target.value)

  }

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between align-items-center'>
          <h2>{initialData ? 'Edit Mediclaim' : 'Add Mediclaim'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Consumer Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label>Name</label>
                  <input
                    type="text"
                    name="Name"
                    required
                    className='form-control'
                    placeholder="Name"
                    value={formState.formData.Name}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Name: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Name && <p className="text-danger">{formState.errors.Name}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Email</label>
                  <input
                    type="email"
                    name="Email"
                    required
                    className='form-control'
                    placeholder="Email"
                    value={formState.formData.Email}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        Email: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.Email && <p className="text-danger">{formState.errors.Email}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    name="MobileNumber"
                    required
                    className='form-control'
                    placeholder="Mobile Number"
                    value={formState.formData.MobileNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        MobileNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.MobileNumber && <p className="text-danger">{formState.errors.MobileNumber}</p>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Company Name</label>
                  <select
                    name="CompanyName"
                    required
                    value={companyType} className='form-select' onChange={(e) => { setCompanyType(e.target.value); onChangeHandle(e) }}>
                    <option value="" disabled hidden>Select Company</option>
                    {companyData && companyData.map((item) => (
                      <option key={item.mediclaim_company_id} value={item.mediclaim_company_id}>
                        {item.mediclaim_company_name}
                      </option>
                    ))}
                  </select>
                  {formState?.errors?.CompanyName && <p className="text-danger">{formState.errors.CompanyName}</p>}
                </div>
                <div className='col-md-6 mb-4 d-grid'>
                  <label>Policy Member</label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      required
                      className='me-2'
                      value="Family"
                      checked={formState.formData.RadioButton === 'Family'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Family
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      className='me-2'
                      value="Individual"
                      checked={formState.formData.RadioButton === 'Individual'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Individual
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="RadioButton"
                      className='me-2'
                      value="Employee"
                      checked={formState.formData.RadioButton === 'Employee'}
                      onChange={(e) => setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          RadioButton: e.target.value
                        }
                      }))}
                    />
                    Employee
                  </label>
                  {formState?.errors?.RadioButton && <p className="text-danger">{formState.errors.RadioButton}</p>}
                </div>
                <div className='col-md-6 mb-4 d-grid'>
                  <label>Policy Type</label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      required
                      className='me-2'
                      value="Fresh"
                      checked={formState.formData.policyRadio === 'Fresh'}
                      onChange={(e) => handlePolicyRatio(e)}
                    />
                    Fresh
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      className='me-2'
                      value="Renewal"
                      checked={formState.formData.policyRadio === 'Renewal'}
                      onChange={(e) => handlePolicyRatio(e)}
                    />
                    Renewal
                  </label>
                  <label className='flex align-items-center'>
                    <input
                      type="radio"
                      name="policyRadio"
                      className='me-2'
                      value="Portability"
                      checked={formState.formData.policyRadio === 'Portability'}
                      onChange={(e) => handlePolicyRatio(e)}
                    />
                    Portability
                  </label>
                  {formState?.errors?.policyRadio && <p className="text-danger">{formState.errors.policyRadio}</p>}
                </div>
              </div>
            </>
          )}
          {currentStep === 2 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Running Policy Details</h3>
              </div>
              {formState.formData.RadioButton === 'Family' && (
                <>
                  <div>
                    <button type="button" className='btn btn-blue mb-2 ' onClick={addFamilyMember}>Add Family Member</button>
                  </div>
                  {(formState.formData.RadioButton === 'Family') && (
                    <>
                      <div className='row mt-2'>
                        <div className='col-md-6 mb-4'>
                          <label>Date of birth</label>
                          <input
                            type="date"
                            name="DateOfBirth"
                            className='form-control'
                            placeholder="Date of Birth"
                            value={formState?.formData?.DateOfBirth}
                            onChange={handleDateChange}
                          />
                          {formState?.errors?.DateOfBirth && (
                            <div className="text-danger">{formState?.errors?.DateOfBirth}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>Age</label>
                          <input
                            type="text"
                            name="Age"
                            className='form-control'
                            placeholder="Age"
                            value={formState?.formData?.Age}
                            readOnly
                          />
                          {formState?.errors?.Age && <div className="text-danger">{formState?.errors?.Age}</div>}
                        </div>
                        <div className='col-md-12 mb-4'>
                          <label>Gender</label>
                          <div className='d-flex'>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Male"
                                checked={formState.formData.Gender === 'Male'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Male
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Female"
                                checked={formState.formData.Gender === 'Female'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Female
                            </label>
                            <label className='me-2'>
                              <input
                                type="radio"
                                name="Gender"
                                className='me-2'
                                value="Other"
                                checked={formState.formData.Gender === 'Other'}
                                onChange={(e) => setFormState(prevState => ({
                                  ...prevState,
                                  formData: {
                                    ...prevState.formData,
                                    Gender: e.target.value
                                  }
                                }))}
                              />
                              Other
                            </label>
                          </div>
                          {formState?.errors?.Gender && (
                            <div className="text-danger">{formState.errors.Gender}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>Relationship With Policy Holder</label>
                          <input
                            type="text"
                            className='form-control'
                            name="RelationshipWithPolicyHolder"
                            placeholder="Relationship with Policy Holder"
                            value={formState.formData.RelationshipWithPolicyHolder}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                RelationshipWithPolicyHolder: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.RelationshipWithPolicyHolder && (
                            <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                          )}
                        </div>
                        <div className='col-md-6 mb-4'>
                          <label>Pre Existing Illness</label>
                          <input
                            type="text"
                            name="PreExistingIllness"
                            className='form-control'
                            placeholder="Pre Existing Illness"
                            value={formState.formData.PreExistingIllness}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                PreExistingIllness: e.target.value
                              }
                            }))}
                          />
                          {formState?.errors?.PreExistingIllness && (
                            <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {formState.formData.RadioButton === 'Family' && (
                    <>
                      {formState.familyMembers && formState.familyMembers.map((member, index) => (
                        <div key={index} className="family-member-section mt-3">
                          <h5>Family Member {index + 1}</h5>
                          <div className='row'>
                            <div className='col-md-6 mb-3'>
                              <label>Member Name</label>
                              <input
                                type="text"
                                className='form-control'
                                value={member.FamilyName}
                                onChange={(e) => handleFamilyMemberChange(index, 'FamilyName', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_FamilyName`] && (
                                <div className="text-danger">{formState.errors[`member${index}_FamilyName`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Date of Birth</label>
                              <input
                                type="date"
                                className='form-control'
                                value={member.DateOfBirth}
                                onChange={(e) => handleFamilyMemberChange(index, 'DateOfBirth', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_DateOfBirth`] && (
                                <div className="text-danger">{formState?.errors[`member${index}_DateOfBirth`]}</div>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label>Age</label>
                              <input
                                type="text"
                                value={member.Age}
                                disabled
                                className="form-control"
                              />
                              {formState?.errors && formState?.errors[`member${index}_Age`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Age`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Gender</label>
                              <div className='d-flex'>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Male"
                                    checked={member.Gender === 'Male'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Male
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Female"
                                    checked={member.Gender === 'Female'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Female
                                </label>
                                <label className='me-2'>
                                  <input
                                    type="radio"
                                    name={`Gender-${index}`}
                                    className='me-2'
                                    value="Other"
                                    checked={member.Gender === 'Other'}
                                    onChange={(e) => handleFamilyMemberChange(index, 'Gender', e.target.value)}
                                  />
                                  Other
                                </label>
                              </div>
                              {formState?.errors && formState?.errors[`member${index}_Gender`] && (
                                <div className="text-danger">{formState.errors[`member${index}_Gender`]}</div>
                              )}
                            </div>
                            <div className='col-md-6 mb-3'>
                              <label>Relationship with Policy Holder</label>
                              <input
                                type="text"
                                className='form-control'
                                value={member.RelationshipWithPolicyHolder}
                                onChange={(e) => handleFamilyMemberChange(index, 'RelationshipWithPolicyHolder', e.target.value)}
                              />
                              {formState?.errors && formState?.errors[`member${index}_RelationshipWithPolicyHolder`] && (
                                <div className="text-danger">{formState.errors[`member${index}_RelationshipWithPolicyHolder`]}</div>
                              )}
                            </div>
                            <div className='col-md-12'>
                              <button
                                type="button"
                                className='btn btn-danger'
                                onClick={() => removeFamilyMember(index)}
                              >
                                Remove Family Member
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <label>Sum Insured</label>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>No Claim Bonus</label>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}
                    </div>
                  </div>
                </>


              )}

              {(formState.formData.RadioButton === 'Individual' || formState.formData.RadioButton === 'Employee') && (
                <>
                  <div className='row mt-2'>
                    <div className='col-md-6 mb-4'>
                      <label>Insured Person Name</label>
                      <input
                        type="text"
                        className='form-control'
                        name="InsuredPersonName"
                        placeholder="Insure Person Name"
                        value={formState.formData?.InsuredPersonName}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            InsuredPersonName: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.InsuredPersonName && (
                        <div className="text-danger">{formState.errors.InsuredPersonName}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'></div>
                    <div className='col-md-6 mb-4'>
                      <label>Date of birth</label>
                      <input
                        type="date"
                        name="DateOfBirth"
                        className='form-control'
                        placeholder="Date of Birth"
                        value={formState.formData?.DateOfBirth}
                        onChange={handleDateChange}
                      />
                      {formState.errors?.DateOfBirth && (
                        <div className="text-danger">{formState.errors.DateOfBirth}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Age</label>
                      <input
                        type="text"
                        name="Age"
                        className='form-control'
                        placeholder="Age"
                        value={formState.formData?.Age}
                        readOnly
                      />
                      {formState?.errors?.Age && <div className="text-danger">{formState.errors.Age}</div>}
                    </div>
                    <div className='col-md-12 mb-4'>
                      <label>Gender</label>
                      <div className='d-flex'>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Male"
                            checked={formState.formData?.Gender === 'Male'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Male
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Female"
                            checked={formState.formData.Gender === 'Female'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Female
                        </label>
                        <label className='me-2'>
                          <input
                            type="radio"
                            name="Gender"
                            className='me-2'
                            value="Other"
                            checked={formState.formData?.Gender === 'Other'}
                            onChange={(e) => setFormState(prevState => ({
                              ...prevState,
                              formData: {
                                ...prevState.formData,
                                Gender: e.target.value
                              }
                            }))}
                          />
                          Other
                        </label>
                      </div>
                      {formState?.errors?.Gender && (
                        <div className="text-danger">{formState.errors.Gender}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Relationship With Policy Holder</label>
                      <input
                        type="text"
                        className='form-control'
                        name="RelationshipWithPolicyHolder"
                        placeholder="Relationship with Policy Holder"
                        value={formState.formData?.RelationshipWithPolicyHolder}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            RelationshipWithPolicyHolder: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.RelationshipWithPolicyHolder && (
                        <div className="text-danger">{formState.errors.RelationshipWithPolicyHolder}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Pre Existing Illness</label>
                      <input
                        type="text"
                        name="PreExistingIllness"
                        className='form-control'
                        placeholder="Pre Existing Illness"
                        value={formState.formData.PreExistingIllness}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            PreExistingIllness: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.PreExistingIllness && (
                        <div className="text-danger">{formState.errors.PreExistingIllness}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>Sum Insured</label>
                      <input
                        type="number"
                        name="SumInsured"
                        className='form-control'
                        placeholder="Sum Insured"
                        value={formState.formData.SumInsured}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            SumInsured: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.SumInsured && (
                        <div className="text-danger">{formState.errors.SumInsured}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-4'>
                      <label>No Claim Bonus</label>
                      <input
                        type="number"
                        name="NoClaimBonus"
                        className='form-control'
                        placeholder="No Claim Bonus"
                        value={formState.formData.NoClaimBonus}
                        onChange={(e) => setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            NoClaimBonus: e.target.value
                          }
                        }))}
                      />
                      {formState?.errors?.NoClaimBonus && (
                        <div className="text-danger">{formState.errors.NoClaimBonus}</div>
                      )}

                    </div>

                  </div>
                </>
              )}

            </>
          )}
          {currentStep === 3 && (
            <>
              <div className="mediclaim-h1">
                <h3>Running Policy Details</h3>
              </div>
              <div className="row">
                <div className="col-md-12 mb-4">
                  <label>Period of Insurance</label>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Number</label>
                  <input
                    type="text"
                    name="RunningPolicyNumber"
                    className="form-control"
                    placeholder="Policy Number"
                    value={formState?.formData?.runningPolicy?.PolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.PolicyNumber && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyNumber}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Zone</label>
                  <input
                    type="text"
                    name="RunningZone"
                    className="form-control"
                    placeholder="Zone"
                    value={formState?.formData?.runningPolicy?.Zone}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          Zone: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.Zone && <div className="text-danger">{formState.errors?.runningPolicy?.Zone}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Tenure</label>
                  <input
                    type="number"
                    name="RunningPolicyTenure"
                    className="form-control"
                    placeholder="Policy Tenure"
                    value={formState?.formData?.runningPolicy?.PolicyTenure}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyTenure: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.PolicyTenure && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyTenure}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Premium Amount</label>
                  <input
                    type="number"
                    name="RunningPremiumAmount"
                    className="form-control"
                    placeholder="Premium Amount"
                    value={formState?.formData?.runningPolicy?.PremiumAmount}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PremiumAmount: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.PremiumAmount && <div className="text-danger">{formState.errors?.runningPolicy?.PremiumAmount}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>From</label>
                  <input
                    type="date"
                    name="poifrom"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.PolicyFrom}
                    onChange={(e) => {
                      const poifrom = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyFrom: poifrom
                          }
                        }
                      }));
                    }}
                  />
                  {formState.errors?.runningPolicy?.PolicyFrom && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyFrom}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>To</label>
                  <input
                    type="date"
                    name="poito"
                    className="form-control"
                    value={formState.formData?.runningPolicy?.PolicyTo}
                    onChange={(e) => {
                      const poito = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyTo: poito,
                            ExpiryDate: poito // Automatically set Expiry Date based on "To" date
                          }
                        }
                      }));
                    }}
                  />
                  {formState.errors?.runningPolicy?.PolicyTo && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyTo}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Additional Sum Insured</label>
                  <input
                    type="number"
                    name="AdditionalSumInsured"
                    className="form-control"
                    placeholder="Additional Sum Insured"
                    value={formState?.formData?.runningPolicy?.AdditionalSumInsured}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isNaN(value) || value < 0) {
                        setFormState(prevState => ({
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "Please enter a valid positive number for Additional Sum Insured"
                          }
                        }));
                      } else {
                        setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            runningPolicy: {
                              ...prevState.formData?.runningPolicy,
                              AdditionalSumInsured: value
                            }
                          },
                          errors: {
                            ...prevState.errors,
                            AdditionalSumInsured: "" // Clear error
                          }
                        }));
                      }
                    }}
                  />
                  {formState.errors?.runningPolicy?.AdditionalSumInsured && <div className="text-danger">{formState.errors?.runningPolicy?.AdditionalSumInsured}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Add On Cover</label>
                  <input
                    type="text"
                    name="AddOnCover"
                    className="form-control"
                    placeholder="Add On Cover"
                    value={formState?.formData?.runningPolicy?.AddOnCover}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          AddOnCover: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.AddOnCover && <div className="text-danger">{formState.errors?.runningPolicy?.AddOnCover}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Issued Date</label>
                  <input
                    type="date"
                    name="PolicyIssuedDate"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.PolicyIssuedDate}
                    onChange={(e) => {
                      const policyIssuedDate = e.target.value;
                      setFormState(prevState => ({
                        ...prevState,
                        formData: {
                          ...prevState.formData,
                          runningPolicy: {
                            ...prevState.formData?.runningPolicy,
                            PolicyIssuedDate: policyIssuedDate
                          }
                        }
                      }));
                    }}
                  />
                  {formState.errors?.runningPolicy?.PolicyIssuedDate && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyIssuedDate}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Expiry Date</label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    className="form-control"
                    value={formState?.formData?.runningPolicy?.ExpiryDate}
                    disabled
                  />
                  {formState.errors?.runningPolicy?.ExpiryDate && <div className="text-danger">{formState.errors?.runningPolicy?.ExpiryDate}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Policy Plan Type</label>
                  <input
                    type="text"
                    name="PolicyPlanType"
                    className="form-control"
                    placeholder="Policy Plan Type"
                    value={formState?.formData?.runningPolicy?.PolicyPlanType}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PolicyPlanType: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.PolicyPlanType && <div className="text-danger">{formState.errors?.runningPolicy?.PolicyPlanType}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee Name</label>
                  <input
                    type="text"
                    name="NomineeName"
                    className="form-control"
                    placeholder="Nominee Name"
                    value={formState?.formData?.runningPolicy?.NomineeName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          NomineeName: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.NomineeName && <div className="text-danger">{formState.errors?.runningPolicy?.NomineeName}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Nominee Relation</label>
                  <input
                    type="text"
                    name="NomineeRelation"
                    className="form-control"
                    placeholder="Nominee Relation"
                    value={formState?.formData?.runningPolicy?.NomineeRelation}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          NomineeRelation: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.runningPolicy?.NomineeRelation && <div className="text-danger">{formState.errors?.runningPolicy?.NomineeRelation}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee DOB</label>
                  <input
                    type="date"
                    name="RunningNomineeDob"
                    className="form-control"
                    placeholder="Nominee DOB"
                    value={formState?.formData?.runningPolicy?.NomineeDob}
                    onChange={handleNomineeDateChange}
                  />
                  {formState.errors?.runningPolicy?.NomineeDob && <div className="text-danger">{formState.errors?.runningPolicy?.NomineeDob}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee Age</label>
                  <input
                    type="text"
                    name="RunningNomineeAge"
                    className="form-control"
                    placeholder="Nominee Age"
                    value={formState?.formData?.runningPolicy?.NomineeAge}
                    readOnly
                  />
                  {formState.errors?.runningPolicy?.NomineeAge && <div className="text-danger">{formState.errors?.runningPolicy?.NomineeAge}</div>}
                </div>
                {formState?.formData?.policyRadio && formState?.formData?.policyRadio === 'Fresh' && <div className="col-md-6 mb-4">
                  <label>Do you have previous policy</label>
                  <select
                    name="PreviousPolicyFlag"
                    className="form-select"
                    value={formState?.formData?.runningPolicy?.PreviousPolicyFlag}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        runningPolicy: {
                          ...prevState.formData?.runningPolicy,
                          PreviousPolicyFlag: e.target.value
                        }
                      }
                    }))}
                  >
                    <option value="" disabled selected>Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {formState.errors?.runningPolicy?.PreviousPolicyFlag && <div className="text-danger">{formState.errors?.runningPolicy?.PreviousPolicyFlag}</div>}
                </div>}
                <div className="col-md-6 mb-4">
                  <label>Upload Running Policy PDF</label>
                  {initialData?.runningPolicy?.CurrentPolicyFile && (
                    <div>
                      <a
                        href={initialData?.runningPolicy?.CurrentPolicyFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          redirectpageproduct(initialData?.runningPolicy?.CurrentPolicyFile); // Use the redirectpage function
                        }}
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          fontSize: "12px",
                        }}
                      >
                        View Existing PDF {initialData?.runningPolicy?.CurrentPolicyFile.slice(0, 5) + '...'}
                      </a>
                    </div>
                  )}
                  <input
                    type="file"
                    name="CurrentPolicyFile"
                    className="form-control"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  {formState?.formData?.runningPolicy?.CurrentPolicyFile && (
                    <p>Selected File: {formState.formData.runningPolicy.CurrentPolicyFile.name}</p>
                  )}
                  {formState.errors?.runningPolicy?.CurrentPolicyFile && <div className="text-danger">{formState.errors?.runningPolicy?.CurrentPolicyFile}</div>}
                </div>
              </div>
            </>
          )}
          {currentStep === 4 && (
            <>
              <div className="mediclaim-h1">
                <h3>Previous Policy Details</h3>
              </div>
              <div className="row">
                {/* Policy Number */}
                <div className="col-md-6 mb-4">
                  <label>Policy Number</label>
                  <input
                    type="text"
                    name="PolicyNumber"
                    className="form-control"
                    placeholder="Policy Number"
                    value={formState?.formData?.previousPolicy?.PolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PolicyNumber && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyNumber}</div>}
                </div>

                {/* Upload PDF for the policy */}
                <div className="col-md-6 mb-4">
                  <label>Upload PDF</label>
                  {initialData?.previousPolicy?.PdfFile && (
                    <div>
                      <a
                        href={initialData?.previousPolicy?.PdfFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          redirectpageproduct(initialData?.previousPolicy?.PdfFile); // Use the redirectpage function
                        }}
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          fontSize: "12px",
                        }}
                      >
                        View Existing PDF {initialData?.previousPolicy?.PdfFile?.slice(0, 5) + '...'}
                      </a>
                    </div>
                  )}
                  <input
                    type="file"
                    name="PdfFile"
                    accept="application/pdf"
                    className="form-control"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type === "application/pdf") {
                        setFormState(prevState => ({
                          ...prevState,
                          formData: {
                            ...prevState.formData,
                            previousPolicy: {
                              ...prevState.formData?.previousPolicy,
                              PdfFile: file,
                            },
                          },
                        }));
                      } else {
                        alert("Please upload a valid PDF file.");
                      }
                    }}
                  />
                  {formState?.formData?.previousPolicy?.PdfFile && (
                    <p>Selected File: {formState.formData.previousPolicy.PdfFile.name}</p>
                  )}
                  {formState.errors?.previousPolicy?.PdfFile && <div className="text-danger">{formState.errors?.previousPolicy?.PdfFile}</div>}
                </div>


                {/* From Date */}
                <div className="col-md-6 mb-4">
                  <label>From</label>
                  <input
                    type="date"
                    name="poifrom"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.PolicyFrom}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyFrom: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PolicyFrom && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyFrom}</div>}
                </div>

                {/* To Date */}
                <div className="col-md-6 mb-4">
                  <label>To</label>
                  <input
                    type="date"
                    name="poito"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.PolicyTo}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PolicyTo: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PolicyTo && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyTo}</div>}
                </div>

                {/* Zone */}
                <div className="col-md-6 mb-4">
                  <label>Zone</label>
                  <input
                    type="text"
                    name="Zone"
                    className="form-control"
                    placeholder="Zone"
                    value={formState.formData?.previousPolicy?.Zone}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          Zone: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.Zone && <div className="text-danger">{formState.errors?.previousPolicy?.Zone}</div>}
                </div>

                {/* Policy Tenure */}
                <div className="col-md-6 mb-4">
                  <label>Policy Tenure</label>
                  <input
                    type="number"
                    name="PolicyTenure"
                    className="form-control"
                    placeholder="Policy Tenure"
                    value={formState.formData?.previousPolicy?.PolicyTenure}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData.previousPolicy,
                          PolicyTenure: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PolicyTenure && <div className="text-danger">{formState.errors?.previousPolicy?.PolicyTenure}</div>}
                </div>

                {/* Premium Amount */}
                <div className="col-md-6 mb-4">
                  <label>Premium Amount</label>
                  <input
                    type="number"
                    name="PremiumAmount"
                    className="form-control"
                    placeholder="Premium Amount"
                    value={formState?.formData?.previousPolicy?.PremiumAmount}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PremiumAmount: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PremiumAmount && <div className="text-danger">{formState.errors?.previousPolicy?.PremiumAmount}</div>}
                </div>

                {/* Renew Date */}
                <div className="col-md-6 mb-4">
                  <label>Renew Date</label>
                  <input
                    type="date"
                    name="RenewDate"
                    className="form-control"
                    value={formState.formData?.previousPolicy?.RenewDate}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          RenewDate: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.RenewDate && <div className="text-danger">{formState.errors?.previousPolicy?.RenewDate}</div>}
                </div>

                {/* Claim Expire in Policy (Yes/No) */}
                <div className="col-md-6 mb-4">
                  <label>Claim Expire in Policy</label>
                  <select
                    name="ClaimExpireInPolicy"
                    className="form-select"
                    value={formState.formData?.previousPolicy?.ClaimExpireInPolicy}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          ClaimExpireInPolicy: e.target.value
                        }
                      }
                    }))}
                  >
                    <option value="" disabled selected>Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {formState.errors?.previousPolicy?.ClaimExpireInPolicy && <div className="text-danger">{formState.errors?.previousPolicy?.ClaimExpireInPolicy}</div>}
                </div>

                {/* Upload Claim Statement PDF */}
                {formState.formData?.previousPolicy?.ClaimExpireInPolicy === 'Yes' && <div className="col-md-6 mb-4">
                  <label>Claim Statement PDF</label>
                  {initialData?.previousPolicy?.ClaimStatementPDFfile && (
                    <div>
                      <a
                        href={initialData?.previousPolicy?.ClaimStatementPDFfile}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default link behavior
                          redirectpageproduct(initialData?.previousPolicy?.ClaimStatementPDFfile); // Use the redirectpage function
                        }}
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          fontSize: "12px",
                        }}
                      >
                        View Existing PDF {initialData?.previousPolicy?.ClaimStatementPDFfile?.slice(0, 5) + '...'}
                      </a>
                    </div>
                  )}
                  <input
                    type="file"
                    name="ClaimStatementPDFfile"
                    accept="application/pdf"
                    className="form-control"
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          ClaimStatementPDFfile: e.target.files[0]
                        }
                      }
                    }))}
                  />
                  {formState?.formData?.previousPolicy?.ClaimStatementPDFfile && (
                    <p>Selected File: {formState.formData.previousPolicy.ClaimStatementPDFfile.name}</p>
                  )}
                  {formState.errors?.previousPolicy?.ClaimStatementPDFfile && <div className="text-danger">{formState.errors?.previousPolicy?.ClaimStatementPDFfile}</div>}
                </div>}
                {/* Nominee Name */}
                <div className="col-md-6 mb-4">
                  <label>Nominee Relation</label>
                  <input
                    type="text"
                    name="NomineeRelation"
                    className="form-control"
                    placeholder="Nominee Relation"
                    value={formState.formData?.previousPolicy?.NomineeRelation}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          NomineeRelation: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.NomineeRelation && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeRelation}</div>}
                </div>

                <div className="col-md-6 mb-4">
                  <label>Nominee Name</label>
                  <input
                    type="text"
                    name="NomineeName"
                    className="form-control"
                    placeholder="Nominee Name"
                    value={formState.formData?.previousPolicy?.NomineeName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          NomineeName: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.NomineeName && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeName}</div>}
                </div>

                {/* Previous Policy Number */}
                <div className="col-md-6 mb-4">
                  <label>Previous Policy Number</label>
                  <input
                    type="text"
                    name="PreviousPolicyNumber"
                    className="form-control"
                    placeholder="Previous Policy Number"
                    value={formState.formData?.previousPolicy?.PreviousPolicyNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          PreviousPolicyNumber: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.PreviousPolicyNumber && <div className="text-danger">{formState.errors?.previousPolicy?.PreviousPolicyNumber}</div>}
                </div>

                {/* Age */}
                <div className="col-md-6 mb-4">
                  <label>Nominee DOB</label>
                  <input
                    type="date"
                    name="PreviousNomineeDob"
                    className="form-control"
                    placeholder="Nominee DOB"
                    value={formState?.formData?.previousPolicy?.NomineeDob}
                    onChange={handleNomineePreviousDateChange}
                  />
                  {formState.errors?.previousPolicy?.NomineeDob && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeDob}</div>}
                </div>
                <div className="col-md-6 mb-4">
                  <label>Nominee Age</label>
                  <input
                    type="number"
                    name="Age"
                    className="form-control"
                    placeholder="Age"
                    value={formState.formData?.previousPolicy?.NomineeAge}
                    readOnly
                  />
                  {formState.errors?.previousPolicy?.NomineeAge && <div className="text-danger">{formState.errors?.previousPolicy?.NomineeAge}</div>}
                </div>

                {/* Product Selection */}
                <div className="col-md-6 mb-4">
                  <label>Select Product</label>
                  <select
                    name="productType"
                    className="form-select"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                  >
                    <option value="" disabled selected hidden>Select Product</option>
                    {productData && productData.map((item) => (
                      <option key={item.mediclaim_product_id} value={item.mediclaim_product_id}>
                        {item.mediclaim_product_name}
                      </option>
                    ))}
                  </select>
                  {formState?.errors?.ProductName && <div className="text-danger">{formState.errors.ProductName}</div>}
                </div>

                {/* Company Name */}
                <div className="col-md-6 mb-4">
                  <label>Company Name</label>
                  <input
                    type="text"
                    name="CompanyName"
                    className="form-control"
                    placeholder="Company Name"
                    value={formState.formData?.previousPolicy?.CompanyName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        previousPolicy: {
                          ...prevState.formData?.previousPolicy,
                          CompanyName: e.target.value
                        }
                      }
                    }))}
                  />
                  {formState.errors?.previousPolicy?.CompanyName && <div className="text-danger">{formState.errors?.previousPolicy?.CompanyName}</div>}
                </div>
              </div>
            </>
          )}


          {currentStep === 5 && (
            <>
              <div className='mediclaim-h1'>
                <h3>Agent Details</h3>
              </div>
              <div className='row'>
                <div className='col-md-6 mb-4'>
                  <label>Agent Name</label>
                  <input
                    type="text"
                    name="AgentName"
                    className='form-control'
                    placeholder="Agent Name"
                    value={formState.formData?.AgentName}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentName: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentName && <div className="text-danger">{formState.errors.AgentName}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Agent Contact Number</label>
                  <input
                    type="text"
                    name="AgentContactNumber"
                    className='form-control'
                    placeholder="Agent Contact Number"
                    value={formState.formData?.AgentContactNumber}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentContactNumber: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentContactNumber && <div className="text-danger">{formState.errors.AgentContactNumber}</div>}
                </div>
                <div className='col-md-6 mb-4'>
                  <label>Agent Code</label>
                  <input
                    type="text"
                    name="AgentCode"
                    className='form-control'
                    placeholder="Agent Code"
                    value={formState.formData?.AgentCode}
                    onChange={(e) => setFormState(prevState => ({
                      ...prevState,
                      formData: {
                        ...prevState.formData,
                        AgentCode: e.target.value
                      }
                    }))}
                  />
                  {formState?.errors?.AgentCode && <div className="text-danger">{formState.errors.AgentCode}</div>}
                </div>
              </div>
            </>
          )}
          <div className="button-group d-flex justify-content-between">
            {currentStep > 1 && (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handlePrev(); }}>Back</button>
            )}
            {currentStep < 5 ? (
              <button className='btn btn-blue' type="button" onClick={(e) => { e.preventDefault(); handleNext(); }}>Next</button>
            ) : (
              !view && currentStep === 5 && (
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              )
            )}
          </div>
        </form>
      </div>
    </div>
  );
};


export default MediclaimPopup;
