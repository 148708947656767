import React, { useEffect, useState } from "react";
import './Builder.css';
import Popup from "../components/Builder-popup";
import Table from "../components/Table";
import DashboardLayout from '../components/DashboardLayout';
import { getAllBuilders } from "../serviceAPI/userAPI";

const Builder = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [editIndex, setEditIndex] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [heading, setHeading] = useState([]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getBuilderData();
  }, []);


  const getBuilderData = async () => {
    const builderData = await getAllBuilders();
    if (builderData?.data && builderData?.data?.length) {
      setData(builderData?.data);
    }
    setHeading([{ key: 'username', head: 'User Name' }, { key: 'email', head: 'Email' }, { key: 'mobileNumber', head: 'Mobile Number' },{ key: 'builderuser.company_name', head: 'Builder Name' }]);
  };

  const handleEdit = (userData) => {
    const globalIndex = data.findIndex((item) => item.user_id === userData.user_id);
    if (globalIndex !== -1) {
      setEditIndex(globalIndex);
      setIsPopupOpen(true);
    }
  };

  const fetchApi = () => {
    getBuilderData();
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Builder</h1>
        <button className="btn dashboard-btn" onClick={togglePopup}>Add</button>
        {isPopupOpen && <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />}
      </div>
      {(
        <Table
          headings={heading}
          data={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
        />
      )}
    </DashboardLayout>
  );
};

export default Builder;
