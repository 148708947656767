import React, { useState, useEffect } from "react";
import "../Table.css";
import Search from "../Search";
import { DOWNLOAD_URL, formatDate } from "../../serviceAPI/userAPI";

const LoanDisburseTable = ({
    headings,
    data,
    handleEdit,
    handleView,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    sortColumn,
    sortDirection,
    handleSort,
    setItemsPerPage,
}) => {
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        filterData(searchTerm);
    }, [data, searchTerm]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const filterData = (searchTerm) => {
        const newData = data.filter((item) =>
            Object.values(item).some((val) =>
                String(val).toLowerCase().includes(searchTerm)
            )
        );
        setFilteredData(newData);
    };

    const clearSearch = () => {
        setSearchTerm("");
        setFilteredData(data);
    };

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortColumn) {
            const aValue = String(a[sortColumn]).toLowerCase();
            const bValue = String(b[sortColumn]).toLowerCase();
            if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
            if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    });

    const getStatusClass = (status) => {
        switch (status) {
            case "documentselected":
                return "status-documentselected";
            case "pickup":
                return "status-pickup";
            case "login":
                return "status-login";
            case "query":
                return "status-query";
            case "sanction":
                return "status-sanction";
            case "disbursement":
                return "status-disburse";
            case "cancel":
                return "status-cancel";
            case "partPayment":
                return "status-partPayment";
            case "completed":
                return "status-completed";
            default:
                return "status-default";
        }
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case "documentselected":
                return "Document Selected";
            case "pickup":
                return "Pickup";
            case "login":
                return "Login";
            case "query":
                return "Query";
            case "sanction":
                return "Sanction";
            case "disbursement":
                return "Disbursement";
            case "cancel":
                return "Cancel";
            case "partPayment":
                return "Part Payment";
            case "interested":
                return "No Status";
            case "completed":
                return "Completed";
            default:
                return "No Status";
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getVisiblePageNumbers = () => {
        const pages = [];
        let startPage, endPage;

        if (totalPages <= 3) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage >= totalPages - 1) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    id={1}
                    onClick={handleClick}
                    className={`page-number ${currentPage === 1 ? "active" : ""}`}
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(
                    <span key="start-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    id={i}
                    onClick={handleClick}
                    className={`page-number ${currentPage === i ? "active" : ""}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <span key="end-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
            pages.push(
                <button
                    key={totalPages}
                    id={totalPages}
                    onClick={handleClick}
                    className={`page-number ${currentPage === totalPages ? "active" : ""
                        }`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };


    const redirectpage = (pdf) => {
        // var file_path = DOWNLOAD_URL + "public/uploads/" + pdf;

        const file_path = DOWNLOAD_URL + pdf;

        // Open file in a new tab
        // window.open(file_path, '_blank');
        console.log(file_path, "file");
        var a = document.createElement("A");
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
        a.target = "_blank"; // Open in a new tab
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div>
            <div className="table-container">
                <div className="table-h">
                    <div className="table-h-c">
                        <Search
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                            clearSearch={clearSearch}
                        />
                    </div>
                    <div className="table-h-c"></div>
                    <div className="show-by table-h-c">
                        <label>Show by Rows:</label>
                        <select
                            value={itemsPerPage}
                            onChange={(e) => {
                                setItemsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                <div style={{width:'100%',overflow:'auto'}}>
                    <table className="universal-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                {headings.map((heading, index) => (
                                    <th key={index} onClick={() => handleSort(heading.key)}>
                                        {heading.head}{" "}
                                        {sortColumn === heading.key
                                            ? sortDirection === "asc"
                                                ? "▲"
                                                : "▼"
                                            : null}
                                    </th>
                                ))}
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData && currentData.length ? (
                                currentData.map((item, index) => {
                                    const loanStatus = item?.status || "";
                                    const disbursementAmount = item?.disbursement_details?.disbursementAmount
                                    const loanAccount = item?.login_details?.loanAccountNumber
                                    const product = item?.login_details?.product
                                    const bank = item?.login_details?.bankName
                                    const disbursementDate = item?.disbursement_details?.disbursementDate
                                    console.log(item?.login_details)
                                    return (
                                        <tr key={index}>
                                            <td>{indexOfFirstItem + index + 1}</td>
                                            {headings &&
                                                headings.map((heading, hIndex) => (
                                                    <td className="show-by" key={hIndex}>
                                                        {heading.key === "pdfname" ? (
                                                            <>
                                                                {item.pdfname &&
                                                                    item.pdfname.split(",").map((pdf, idx) => (
                                                                        <div key={idx}>
                                                                            <a>
                                                                                Year: {item.categoryname.split(",")[idx]}{" "}
                                                                                --{" "}
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                onClick={() => redirectpage(pdf)}
                                                                            >
                                                                                {pdf.slice(37)}
                                                                            </a>
                                                                            {idx < item.pdfname.split(",").length - 1
                                                                                ? ", "
                                                                                : ""}
                                                                        </div>
                                                                    ))}
                                                            </>
                                                        ) : heading.key === "status" ? (
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <span className={`status-box ${getStatusClass(loanStatus)}`}>
                                                                    {getStatusLabel(loanStatus) || "No Status"}
                                                                </span>
                                                            </div>
                                                        ) : heading.key === "details.login_details.loanAccountNumber" ? loanAccount : heading.key === "details.login_details.loanAmount" ? disbursementAmount : heading.key === "details.login_details.product" ? product : heading.key === "details.login_details.bank" ? bank : heading.key === "details.login_details.disbursementDate" ? formatDate(disbursementDate) :(
                                                            item[heading.key]
                                                        )}
                                                    </td>
                                                ))}

                                            <td>
                                                <div style={{ padding: "0px", display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                    <img
                                                        className="table-edit"
                                                        src="/Assets/edit-icon.png"
                                                        alt="edit"
                                                        onClick={() => handleEdit(item)}
                                                    />
                                                    <img
                                                        className="table-view"
                                                        src="/Assets/view-icon.png"
                                                        alt="view"
                                                        onClick={() => handleView(item)} // Trigger view
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={headings.length + 2}>No record found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {filteredData && (
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        {getVisiblePageNumbers()}
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoanDisburseTable;
