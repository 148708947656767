import React, { useState, useEffect } from 'react';
import './popup-u.css';
import { addRoleUser, getCategoryById, getRoles, updateInquieryUser, updateRoleUser } from '../serviceAPI/userAPI';
import { useToaster } from './Toaster';
import FlagDropdown from '../pages/Flag';

const InquiryPopup = ({ isOpen, onClose, fetchApi, initialData }) => {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const addToast = useToaster()

  useEffect(() => {
    if (initialData) {
      setName(initialData.user_name);
      setMobile(initialData.mobile_no); // Updated key to match backend
      setEmail(initialData.email);
      setService(initialData.services);
      setStatus(initialData.status || '');
    } else {
      setName('');
      setMobile(''); // Updated key to match backend
      setEmail('');
      setService('');
      setStatus('');
    }
  }, [initialData]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const regex = /^\d{10}$/;
    if (!regex.test(mobile)) {
      alert('mobile number invalid')
      return
    }

    const userData = {
      user_name: name.trim(),
      mobile_no: mobile,
      email,
      service,
      status
    };


    if (initialData && initialData.id) {
      userData.id = initialData.id
      const response = await updateInquieryUser(userData, addToast);
      if (response.status) {
        setMessage('User registered successfully!');
        fetchApi();
        onClose();
      } else {
        onClose();
      }
    } else {
      // const response = await addRoleUser(userData, addToast);
      // if (response.status) {
      //   setMessage('User registered successfully!');
      //   fetchApi();
      //   onClose();
      // } else {
      //   onClose();
      // }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='popup-header d-flex justify-content-between'>
          <h2>{initialData ? 'Edit Inquiry User' : 'Add Inquiry User'}</h2>
          <span className="close-btn" onClick={onClose}>&times;</span>
        </div>
        <form className="popup-form" onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Name</label>
              <input type="text" placeholder='Name' className='form-control' value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Email</label>
              <input type="email" placeholder='Email' className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className='col-md-6 mb-4'>
              <label className="form-label">Mobile Number</label>
              <div style={{ display: 'flex' }}>
                <FlagDropdown />
                <input type="text" style={{ margin: '0' }} placeholder='Mobile Number' className='form-control' value={mobile} onChange={(e) => setMobile(e.target.value)} required />
              </div>

            </div>
            <div className='col-md-6 mb-4'>
              <label for="service" className="form-label">Services</label>
              <select name="service"
                value={service}
                required
                onChange={(e) => setService(e.target.value)}
                className="form-select">
                <option value="" disabled>Services</option>
                <option value="Loan Insurance">Loan Insurance</option>
                <option value="Life Insurance">Life Insurance</option>
                <option value="Vehicle Insurance">Vehicle Insurance</option>
                <option value="Mediclaim Insurance">Mediclaim Insurance</option>
              </select>
            </div>
            <div className='col-md-6 mb-4 d-grid'>
              <label className='form-label'>Status</label>
              <label className='flex align-items-center form-label'>
                <input
                  type="radio"
                  name="status"
                  required
                  className='me-2'
                  value="Inquired"
                  checked={status === 'Inquired'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                Inquired
              </label>
              <label className='flex align-items-center form-label'>
                <input
                  type="radio"
                  name="status"
                  className='me-2'
                  value="Process"
                  checked={status === 'Process'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                Process
              </label>
              <label className='flex align-items-center form-label'>
                <input
                  type="radio"
                  name="status"
                  className='me-2'
                  value="Rejected"
                  checked={status === 'Rejected'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                Rejected
              </label>
            </div>
          </div>
          <button className='btn btn-blue' type="submit">{initialData ? 'Update' : 'Add'}</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default InquiryPopup;
