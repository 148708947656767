import React, { useEffect, useState } from 'react';
import './Loandisbuss.css';
import Popup from '../components/Loandisburse-popup';
import Table from '../components/Table';
import DashboardLayout from '../components/DashboardLayout';
import { getAllConsumers, getAllLoanConsumerDetail, getAllLoanDisburseConsumer, getAllVerticle, getAllVerticleUser } from '../serviceAPI/userAPI';
import LoanDisburseTable from '../components/Loan-Table/Loan-Completed';
import PopupModal from '../components/View-Loan-Details.popup';

const Loandisbuss = () => {
  const initialData = [
    { Name: 'John Doe', 'Mobile Number': '1234567890', Email: 'john@example.com' },
    { Name: 'Jane Smith', 'Mobile Number': '9876543210', Email: 'jane@example.com' },
    { Name: 'Samuel Green', 'Mobile Number': '5555555555', Email: 'sam@example.com' },
  ];

  const getStartOfFinancialYear = () => {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
    return new Date(year, 3, 2).toISOString().split('T')[0]; // 1st April of the financial year
  };

  const getEndOfFinancialYear = () => {
    const today = new Date();
    const year = today.getMonth() >= 3 ? today.getFullYear() + 1 : today.getFullYear();
    console.log(year,'ss')
    return new Date(year, 3, 1).toISOString().split('T')[0]; // Ensures 31st March
  };
  const [data, setData] = useState([]);
  const [verticle, setVerticle] = useState([]);
  const [verticleUser, setVerticleUser] = useState([]);
  const [startDate, setStartDate] = useState(getStartOfFinancialYear());
  const [endDate, setEndDate] = useState(getEndOfFinancialYear());
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [viewIndex, setViewIndex] = useState(null);
  const [isViewPopupOpen, setIsViewPopupOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [heading, setHeading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    setEditIndex(null);
  };

  useEffect(() => {
    getAllLoanConsumerData()
  }, []);

  const handleView = async (userData) => {
    const globalIndex = data.findIndex((item) => item.laon_id === userData.laon_id);
    if (globalIndex !== -1) {
      setViewIndex(globalIndex);
      const laon_id = data[globalIndex]?.laon_id; // Get loan ID from selected row
      await getLoanConsumerDetail(laon_id); // Fetch detailed loan data for view
      setIsViewPopupOpen(true)
    }
  };

  const getLoanConsumerDetail = async (laon_id) => {
    const consumerData = await getAllLoanConsumerDetail({ laon_id }); // Send laon_id to get loan details
    if (consumerData?.data) {
      setDetail(consumerData?.data); // Set the detailed data to state
    }
  };

  const getAllLoanConsumerData = async () => {
    const consumerData = await getAllLoanDisburseConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    
    setHeading([{ key: 'details.login_details.disbursementDate', head: 'Disbursement Date' },{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Disbursement Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' }, { key: 'status', head: 'Status' }, { key: 'pdfname', head: 'Detail' }]);
  };

  const handleEdit = (userData) => {
    const globalIndex = data.findIndex((item) => item.laon_id === userData.laon_id);
    if (globalIndex !== -1) {
      setEditIndex(globalIndex);
      setIsPopupOpen(true);
    }
  };

  const handleSort = (column) => {
    const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(direction);
  };

  const fetchApi = () => {
    getAllLoanConsumerData();
  };

  const handleVerticalChange = (index, newVertical) => {
    const updatedData = data.map((item, idx) =>
      idx === index ? { ...item, vertical: newVertical } : item
    );
    setData(updatedData);
  };

  const toggleViewPopup = () => {
    setIsViewPopupOpen(!isViewPopupOpen);
    setViewIndex(null);
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    const consumerData = await getAllLoanDisburseConsumer({ startDate, endDate });
    if (consumerData?.data && consumerData?.data?.length) {
      setData(consumerData?.data);
    } else {
      setData([]);
    }
    setHeading([{ key: 'details.login_details.disbursementDate', head: 'Disbursement Date' },{ key: 'userConsumers.username', head: 'Name' }, { key: 'userConsumers.mobileNumber', head: 'Mobile Number' }, { key: 'details.login_details.product', head: 'Product' }, { key: 'details.login_details.bank', head: 'Bank' }, { key: 'details.login_details.loanAmount', head: 'Disbursement Amount' }, { key: 'details.login_details.loanAccountNumber', head: 'Loan Account No.' }, { key: 'status', head: 'Status' }, { key: 'pdfname', head: 'Detail' }]);
  };

  return (
    <DashboardLayout>
      <div className='d-flex justify-content-between align-items-center dashboard-title'>
        <h1>Completed</h1>
        <button className="btn dashboard-btn d-none" onClick={togglePopup}>Add</button>
        {isPopupOpen && <Popup
          isOpen={isPopupOpen}
          onClose={togglePopup}
          fetchApi={fetchApi}
          initialData={editIndex !== null ? data[editIndex] : null}
        />}
        {isViewPopupOpen && (
          <PopupModal
            isOpen={isViewPopupOpen}
            onClose={toggleViewPopup}
            fetchApi={fetchApi}
            initialData={detail}
            mode="view" // Pass the mode prop as 'view' for viewing the loan details
          />
        )}
      </div>
      <div className="filter-section">
        <div className="filter-inputs">
          <div>
            <label>Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="form-control"
            />
          </div>
          <div>
            <label>End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>
      {(
        <LoanDisburseTable
          headings={heading}
          data={data}
          verticleOptions={verticle}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          handleEdit={handleEdit}
          handleView={handleView}
          handleSort={handleSort}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          setItemsPerPage={setItemsPerPage}
          onVerticalChange={handleVerticalChange}
        />
      )}
    </DashboardLayout>
  );
};

export default Loandisbuss;
