import React, { useState, useEffect } from "react";
import "./Vehicle-Table.css";
import { useNavigate, useParams } from "react-router-dom";
import { DOWNLOAD_URL } from "../../serviceAPI/userAPI";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from 'js-cookie';
import Search from "../../components/Search";

const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';

const VehicleTable = ({
    headings,
    data,
    handleEdit,
    handleViewClick,
    handleDelete,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    sortColumn,
    sortDirection,
    handleSort,
    setItemsPerPage,
    verticleOptions,
    onVerticalChange,
    onStatusChange,
}) => {
    const [filteredData, setFilteredData] = useState(data);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStatus, setSelectedStatus] = useState({});
    const [inputValue, setInputValue] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        filterData(searchTerm);
    }, [data, searchTerm]);

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        setCurrentPage(1);
    };

    const filterData = (searchTerm) => {
        const newData = data.filter((item) =>
            Object.values(item).some((val) =>
                String(val).toLowerCase().includes(searchTerm)
            )
        );
        setFilteredData(newData);
    };
    console.log(filteredData, 'djfkd')

    const clearSearch = () => {
        setSearchTerm("");
        setFilteredData(data);
    };

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortColumn) {
            const aValue = String(a[sortColumn]).toLowerCase();
            const bValue = String(b[sortColumn]).toLowerCase();
            if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
            if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const getVisiblePageNumbers = () => {
        const pages = [];
        let startPage, endPage;

        if (totalPages <= 3) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 2) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage >= totalPages - 1) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        if (startPage > 1) {
            pages.push(
                <button
                    key={1}
                    id={1}
                    onClick={handleClick}
                    className={`page-number ${currentPage === 1 ? "active" : ""}`}
                >
                    1
                </button>
            );
            if (startPage > 2) {
                pages.push(
                    <span key="start-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    id={i}
                    onClick={handleClick}
                    className={`page-number ${currentPage === i ? "active" : ""}`}
                >
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(
                    <span key="end-ellipsis" className="ellipsis">
                        ...
                    </span>
                );
            }
            pages.push(
                <button
                    key={totalPages}
                    id={totalPages}
                    onClick={handleClick}
                    className={`page-number ${currentPage === totalPages ? "active" : ""
                        }`}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };


    console.log(inputValue);

    const redirectpage = (pdf) => {
        // var file_path = DOWNLOAD_URL + "public/uploads/" + pdf;
        const file_path = DOWNLOAD_URL + pdf;
        console.log(file_path, "file");
        var a = document.createElement("A");
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
        a.target = "_blank"; // Open in a new tab
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const redirectpageproduct = (pdfPath) => {
        // const file_path = `${DOWNLOAD_URL}public${pdfPath}`;
        const file_path = DOWNLOAD_URL + pdfPath;
        console.log(file_path, "file");

        // Create a temporary <a> element
        const a = document.createElement("a");
        a.href = file_path;
        a.target = "_blank"; // Open in a new tab
        a.download = file_path; // Extract filename from path

        // Trigger the click programmatically
        document.body.appendChild(a);
        a.click();

        // Remove the element after triggering
        document.body.removeChild(a);
    };

    return (
        <div>
            <div className="table-container">
                <div className="table-h">
                    <div className="table-h-c">
                        <Search
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                            clearSearch={clearSearch}
                        />
                    </div>
                    <div className="table-h-c"></div>
                    <div className="show-by table-h-c">
                        <label>Show by Rows:</label>
                        <select
                            value={itemsPerPage}
                            onChange={(e) => {
                                setItemsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                </div>
                <table className="universal-table">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            {headings.map((heading, index) => (
                                <th key={index} onClick={() => handleSort(heading.key)}>
                                    {heading.head}{" "}
                                    {sortColumn === heading.key
                                        ? sortDirection === "asc"
                                            ? "▲"
                                            : "▼"
                                        : null}
                                </th>
                            ))}
                            <th>View</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData && currentData.length ? (
                            currentData.map((item, index) => {
                                const { user_consumer_id, mediclaimproductpdfs } = item;
                                let interestedLoanStatus = selectedStatus[user_consumer_id]?.status;
                                if (selectedStatus[user_consumer_id]?.status === "interested") {
                                    interestedLoanStatus = ''
                                }
                                return (
                                    <tr key={index}>
                                        <td>{indexOfFirstItem + index + 1}</td>
                                        {headings &&
                                            headings.map((heading, hIndex) => (
                                                <td className="show-by" key={hIndex}>
                                                    {heading.key === "roleDisplay" ? (
                                                        item.category &&
                                                        item.category.map((Item) => (
                                                            <div key={Item.id}>
                                                                (Vertical ={" "}
                                                                {Item.category_id == 2
                                                                    ? "LOAN"
                                                                    : Item.category_id == 4
                                                                        ? "MEDICLIAM"
                                                                        : Item.category_id == 5
                                                                            ? "LIFE INSURANCE"
                                                                            : "VEHICLE INSURANCE"}{" "}
                                                                : Role User : {Item["userRoles.username"]})
                                                            </div>
                                                        ))
                                                    ) : heading.key === "pdfname" ? (
                                                        <>
                                                            {item.pdfname &&
                                                                item.pdfname.split(",").map((pdf, idx) => (
                                                                    <div key={idx}>
                                                                        <a>
                                                                            Year: {item.categoryname.split(",")[idx]}{" "}
                                                                            --{" "}
                                                                        </a>
                                                                        <a
                                                                            href="#"
                                                                            onClick={() => redirectpage(pdf)}
                                                                        >
                                                                            {pdf.slice(37)}
                                                                        </a>
                                                                        {idx < item.pdfname.split(",").length - 1
                                                                            ? ", "
                                                                            : ""}
                                                                    </div>
                                                                ))}
                                                        </>
                                                    ) : // item.category && item.category.map((Item) => (
                                                        //   <div key={Item.id}>
                                                        //     (Vertical = {Item.category_id == 2 ? 'LOAN' : Item.category_id == 4 ? 'MEDICLIAM' : Item.category_id == 5 ? 'LIFE INSURANCE' : 'VEHICLE INSURANCE'} : Role User : {Item['userRoles.username']})
                                                        //   </div>
                                                        // ))
                                                        heading.key === "mediclaimproductpdfs" ? (
                                                            <>
                                                                <div className="pdf-grid">
                                                                    {mediclaimproductpdfs &&
                                                                        mediclaimproductpdfs.map((pdf, pdfIndex) => (
                                                                            <div
                                                                                key={pdf.mediclaim_pdf_id}
                                                                                className="pdf-item"
                                                                                style={{
                                                                                    border: "1px solid #ddd",
                                                                                    padding: "10px",
                                                                                    margin: "5px",
                                                                                    display: "inline-block",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        fontSize: "14px",
                                                                                        marginBottom: "5px",
                                                                                        fontWeight: "bold",
                                                                                    }}
                                                                                >
                                                                                    {pdf.pdf_name}
                                                                                </p>
                                                                                <a
                                                                                    href={pdf.pdf_path}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default link behavior
                                                                                        redirectpageproduct(pdf.pdf_path); // Use the redirectpage function
                                                                                    }}
                                                                                    style={{
                                                                                        color: "blue",
                                                                                        textDecoration: "underline",
                                                                                        fontSize: "12px",
                                                                                    }}
                                                                                >
                                                                                    View PDF
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </>
                                                        ) :
                                                            heading.key === "is_from_builder_user" &&
                                                                (item.is_from_builder_user == 1 ||
                                                                    item.is_from_builder_user == 0) ? (
                                                                <div>
                                                                    {item.is_from_builder_user ? "Yes" : "No"}
                                                                </div>
                                                            ) : heading.key === "is_from_loan_manager_user" &&
                                                                (item.is_from_loan_manager_user == 1 ||
                                                                    item.is_from_loan_manager_user == 0) ? (
                                                                <div>
                                                                    {item.is_from_loan_manager_user ? "Yes" : "No"}
                                                                </div>
                                                            ) : (
                                                                item[heading.key]
                                                            )}
                                                </td>
                                            ))}

                                        <td>
                                            <div
                                                style={{ padding: "0px" }}
                                            >
                                                <img
                                                    className="table-view"
                                                    src="/Assets/view.png"
                                                    alt="view"
                                                    onClick={() => handleViewClick(item)}
                                                />
                                                {/* {user && user.role_id == 1 && <DeleteIcon sx={{ color: "red",marginLeft: "10px",cursor:"pointer" }} onClick={() => handleDelete(item)}/>}*/}
                                            </div>

                                        </td>
                                        <td>
                                            <div
                                                style={{ padding: "0px" }}
                                            >
                                                <img
                                                    className="table-edit"
                                                    src="/Assets/edit.png"
                                                    alt="edit"
                                                    onClick={() => handleEdit(item)}
                                                />
                                                {/* {user && user.role_id == 1 && <DeleteIcon sx={{ color: "red",marginLeft: "10px",cursor:"pointer" }} onClick={() => handleDelete(item)}/>}*/}
                                            </div>

                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={headings.length + 2}>No record found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {filteredData && (
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        {getVisiblePageNumbers()}
                        <button
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VehicleTable;
