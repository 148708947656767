// src/dashboard/Dashboard.js
import React, { useEffect, useState } from "react";
import DashboardLayout from "../components/DashboardLayout";
import "./Dashboard.css";
import { getLoanAmounFilterDate, getUserCountList } from "../serviceAPI/userAPI";
import Cookies from 'js-cookie';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, Colors } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { PolarArea } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, Title);

const Dashboard = () => {
  

  const user = (Cookies.get('user') && JSON.parse(Cookies.get('user'))) || '';
  const categoryId = Cookies.get('category');

  const [data, setData] = useState({
    "consumerCount": 0,
    "builderUserCount": 0,
    "loanUserCount": 0,
    "loanInterstedUserCount": 0,
    "loanNotInterstedUserCount": 0,
    "loanNotAssignUserCount": 0,
    "loanDisburseUserCount": 0,
    "loanDocumentUserCount": 0,
    "loanPartUserCount": 0,
    "loanPartCancelCount": 0,
    "loanPickupUserCount": 0,
    "loanQueryUserCount": 0,
    "loanLoginUserCount": 0,
    "loanSensonUserCount": 0,
    "mediclaimUserCount": 0,
    "expiredThisMonth": 0,
    "expiredThisMonthVehicle": 0,
    "expiredThisWeek": 0,
    "expiredThisWeekVehicle": 0,
    "expiredThisYear": 0,
    "expiredThisYearVehicle": 0,
    "vehicleUserCount": 0,
    "loanCompletedUserCount": 0,
    "lifeUserCount": 0,
  });

  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    getUserCountData();
  }, []);

  const handleChartClick = (event, elements, dataKey) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const label = chartData.labels[index];

      // Redirect to the appropriate page based on the chart segment clicked
      switch (label) {
        case 'Interested':
          window.location.href = '/loan/interested';
          break;
        case 'Not Interested':
          window.location.href = '/loan/not-interested';
          break;
        case 'Not Assigned':
          window.location.href = '/loan';
          break;
        case 'Completed':
          window.location.href = '/loan/completed';
          break;

        default:
          console.warn('No matching case for label:', label);
      }
    }
  };

  const getUserCountData = async () => {
    const roleData = await getUserCountList();
    setData({
      "consumerCount": roleData?.data?.consumerCount || 0,
      "builderUserCount": roleData?.data?.builderUserCount || 0,
      "loanUserCount": roleData?.data?.loanUserCount || 0,
      "loanInterstedUserCount": roleData?.data?.loanInterstedUserCount || 0,
      "loanNotInterstedUserCount": roleData?.data?.loanNotInterstedUserCount || 0,
      "loanNotAssignUserCount": roleData?.data?.loanNotAssignUserCount || 0,
      "loanDocumentUserCount": roleData?.data?.loanDocumentUserCount || 0,
      "loanPickupUserCount": roleData?.data?.loanPickupUserCount || 0,
      "loanQueryUserCount": roleData?.data?.loanQueryUserCount || 0,
      "loanLoginUserCount": roleData?.data?.loanLoginUserCount || 0,
      "loanSensonUserCount": roleData?.data?.loanSensonUserCount || 0,
      "mediclaimUserCount": roleData?.data?.mediclaimUserCount || 0,
      "expiredThisMonth": roleData?.data?.expiredThisMonth || 0,
      "expiredThisMonthVehicle": roleData?.data?.expiredThisMonthVehicle || 0,
      "expiredThisWeek": roleData?.data?.expiredThisWeek || 0,
      "expiredThisWeekVehicle": roleData?.data?.expiredThisWeekVehicle || 0,
      "expiredThisYear": roleData?.data?.expiredThisYear || 0,
      "expiredThisYearVehicle": roleData?.data?.expiredThisYearVehicle || 0,
      "vehicleUserCount": roleData?.data?.vehicleUserCount || 0,
      "loanDisburseUserCount": roleData?.data?.loanDisburseUserCount || 0,
      "loanPartUserCount": roleData?.data?.loanPartUserCount || 0,
      "totalLoandAmount": roleData?.data?.totalLoandAmount || 0,
      "totalDisbursedAmount": roleData?.data?.totalDisbursedAmount || 0,
      "loanPartCancelCount": roleData?.data?.loanPartCancelCount || 0,
      "loanCompletedUserCount": roleData?.data?.loanCompletedUserCount || 0,
      "lifeUserCount": roleData?.data?.lifeUserCount || 0,
    });
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    const filteredData = await getLoanAmounFilterDate({
      start_date: startDate,
      end_date: endDate,
    });
    setData({
      ...data,
      totalLoandAmount: filteredData?.data?.totalLoandAmount || 0,
      totalDisbursedAmount: filteredData?.data?.totalDisbursedAmount || 0,
    });
  };

  const chartData = {
    labels: ['Interested', 'Not Interested','Completed','Not Assigned'],
    datasets: [{
      data: [
        data.loanInterstedUserCount,
        data.loanNotInterstedUserCount,
        data.loanCompletedUserCount,
        data.loanNotAssignUserCount
      ],
      backgroundColor: [
        '#4CAF50',
        '#F44336',
        '#2196F3', 
        '#FFC107'
      ],
      borderColor: [
        '#4CAF50',
        '#F44336',
        '#2196F3', 
        '#FFC107'
      ],
      borderWidth: 2,
      hoverBackgroundColor: [
        '#4CAF50',
        '#F44336',
        '#2196F3', 
        '#FFC107'
      ]
    }]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 20,
          font: {
            size: 14
          }
        }
      },
      title: {
        display: true,
        text: 'Loan Users Count',
        font: {
          size: 25
        },
        padding: {
          top: 20,
          bottom: 20
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    cutout: '60%',
    onClick: (event, elements) => handleChartClick(event, elements, 'loanUsers'),
  };

  const chartData1 = {
    labels: ['Document Select', 'Pickup', 'Login', 'Query', 'Sanction', 'Disbursement', 'Part Payment', 'Cancel'],
    datasets: [{
      data: [
        data.loanDocumentUserCount,
        data.loanPickupUserCount,
        data.loanLoginUserCount,
        data.loanQueryUserCount,
        data.loanSensonUserCount,
        data.loanDisburseUserCount,
        data.loanPartUserCount,
        data.loanPartCancelCount,
      ],
      backgroundColor: [
        '#a8d0e6', // Soft light blue for Document Select
        '#ff9f43', // Warm orange for Pickup
        '#34ace0', // Soft cyan blue for Login
        '#ff6b81', // Soft coral red for Query
        '#4caf50', // Fresh green for Sanction
        '#6c5ce7', // Soft purple for Disbursement
        '#feca57', // Soft yellow for Part Payment
        '#ff3838'  // Bold red for Cancel
      ],
      borderColor: [
        '#a8d0e6', // Soft light blue for Document Select
        '#ff9f43', // Warm orange for Pickup
        '#34ace0', // Soft cyan blue for Login
        '#ff6b81', // Soft coral red for Query
        '#4caf50', // Fresh green for Sanction
        '#6c5ce7', // Soft purple for Disbursement
        '#feca57', // Soft yellow for Part Payment
        '#ff3838'  // Bold red for Cancel
      ],
      borderWidth: 2,
      hoverBackgroundColor: [
        '#b9d9eb', // Lighter blue for Document Select
        '#ffb065', // Lighter orange for Pickup
        '#51c8f0', // Lighter cyan blue for Login
        '#ff8597', // Lighter coral red for Query
        '#66bb6a', // Lighter green for Sanction
        '#8573eb', // Lighter purple for Disbursement
        '#fed67a', // Lighter yellow for Part Payment
        '#ff6161'  // Lighter red for Cancel
      ]
    }]
  };


  const chartOptions1 = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 20,
          font: {
            size: 14
          }
        }
      },
      title: {
        display: true,
        text: 'Loan Work Process Count',
        font: {
          size: 25,
          fontFamily: 'var(--font-familt-marcellus)',
          color: 'var(--font-color)'
        },
        padding: {
          top: 20,
          bottom: 20
        }
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    cutout: '60%'
  };

  const formatNumber = (number) => {
    return number == 0 ? 0 : number?.toLocaleString();
  };

  return (
    <DashboardLayout>
      <div className="dashboard-container">
        <div className="row">
          {(user && user.role_id == 1) && <div className="col-12">
            <div className="d-flex align-items-center mb-3">
              <div className="total-consumer">Total Consumer -</div>
              <div className="number">{data.consumerCount}</div>
            </div>
          </div>}
          {(user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <div className="col-md-3">
            <div className="board-item blue-bg">
              <h2 className="name">Loan Consumer</h2>
              <p className="animated-count text-highlight">{data.loanUserCount}</p>
            </div>
          </div>}
          {(user && user.role_id == 1 || (categoryId && categoryId.includes(4))) && <div className="col-md-3">
            <div className="board-item green-bg">
              <h2 className="name">Mediclaim Consumer</h2>
              <p className="animated-count text-highlight">{data.mediclaimUserCount}</p>
            </div>
          </div>}
          {(user && user.role_id == 1 || (categoryId && categoryId.includes(6))) && <div className="col-md-3">
            <div className="board-item orange-bg">
              <h2 className="name">Vehicle Consumer</h2>
              <p className="animated-count text-highlight">{data.vehicleUserCount}</p>
            </div>
          </div>}
          {(user && user.role_id == 1 || (categoryId && categoryId.includes(5))) && <div className="col-md-3">
            <div className="board-item yellow-bg">
              <h2 className="name">Total Life Insurance Consumer</h2>
              <p className="animated-count text-highlight">{data.lifeUserCount}</p>
            </div>
          </div>}
        </div>
        <hr></hr>
        {(user && user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <div className="row pb-3">
          <h2 className="total-consumer">Loan</h2>
          <div className="col-md-6">
            <div className="card shadow p-4">
              <div className="chart-container loan-chart" style={{ height: '400px' }}>
                <Doughnut data={chartData} options={chartOptions} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card shadow p-4">
              <div className="chart-container loan-chart" style={{ height: '400px' }}>
                <Doughnut data={chartData1} options={chartOptions1} />
              </div>
            </div>
          </div>

        </div>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <hr></hr>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <div className="filter-section">
          <div className="filter-inputs">
            <div>
              <label>Start Date:</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="form-control"
              />
            </div>
            <div>
              <label>End Date:</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <button className="search-button" onClick={handleSearch}>Search</button>
        </div>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <hr />}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(2))) && <div className="row">
          <div className="col-md-6">
            <div className="board-item blue-bg">
              <h2 className="name">Login Amount</h2>
              <p className="animated-count text-highlight">{formatNumber(data.totalLoandAmount)}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="board-item green-bg">
              <h2 className="name">Disubursement Amount</h2>
              <p className="animated-count text-highlight">{formatNumber(data.totalDisbursedAmount)}</p>
            </div>
          </div>
        </div>}

        {(user && user.role_id == 1 || (categoryId && categoryId.includes(4))) && <div className="row pb-3">
          <div className="col-12">
            <h2 className="total-consumer mb-4">Mediclaim</h2>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Mediclaim Expiring</h3>
              <p>Week</p>
              <div className="number">{data.expiredThisWeek}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Mediclaim Expiring</h3>
              <p>Month</p>
              <div className="number">{data.expiredThisMonth}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Mediclaim Expiring</h3>
              <p>Year</p>
              <div className="number">{data.expiredThisYear}</div>
            </div>
          </div>
        </div>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(6))) && <hr></hr>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(6))) && <div className="row pb-3">
          <div className="col-12">
            <h2 className="total-consumer mb-4">Vehicle</h2>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Vehicle Expiring</h3>
              <p>Week</p>
              <div className="number">{data.expiredThisWeekVehicle}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Vehicle Expiring</h3>
              <p>Month</p>
              <div className="number">{data.expiredThisMonthVehicle}</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card board-item mediclaim-card text-center">
              <h3>Vehicle Expiring</h3>
              <p>Year</p>
              <div className="number">{data.expiredThisYearVehicle}</div>
            </div>
          </div>
        </div>}
        {(user && user.role_id == 1 || (categoryId && categoryId.includes(6))) && <hr></hr>}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
